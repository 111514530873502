export class NewsflashStatement {
	id: number;
	created: Date;
	titleNl: string;
	titleFr: string;
	bodyFr: string;
	bodyNl: string;
	publishStartDate: Date;
	publishEndDate: Date;
	confirmationFlag: boolean;
	visibility: boolean;
	newsType: NewType;
  brokerAccessGroup: any;
  blacklistedBrokerAccessGroup: any;

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || null;
			this.created = data.created || null;
			this.titleNl = data.titleNl || null;
			this.titleFr = data.titleFr || null;
			this.bodyFr = data.bodyFr || null;
			this.bodyNl = data.bodyNl || null;
			this.publishStartDate = data.publishStartDate || null;
			this.publishEndDate = data.publishEndDate || null;
			this.confirmationFlag = data.confirmationFlag !== undefined ? data.confirmationFlag : null;
			this.visibility = data.visibility !== undefined ? data.visibility : null;
			this.newsType = data.newsType || null;
      this.brokerAccessGroup = data.brokerAccessGroup || null;
      this.blacklistedBrokerAccessGroup = data.blacklistedBrokerAccessGroup || null;
		}
	}
}

export class NewsflashItem {
	id: number;
	publishStartDate: Date;
	titleNl: string;
	titleFr: string;
	confirmed: boolean;
	newsType: NewType;

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || null;
			this.publishStartDate = data.publishStartDate || null;
			this.titleNl = data.titleNl || null;
			this.titleFr = data.titleFr || null;
			this.confirmed = data.confirmed || null;
			this.newsType = data.newsType || null;
		}
	}
}

export class NewType {
	id: number;
	definition: string;

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || null;
			this.definition = data.definition || null;
		}
	}
}

export class NewsPagination {
	page: number;
	pageSize: number;
	confirmed: boolean;
	needConfirmation: boolean;

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.page = data.page || null;
			this.pageSize = data.pageSize || null;
			this.confirmed = data.confirmed !== undefined ? data.confirmed : undefined;
			this.needConfirmation = data.needConfirmation !== undefined ? data.needConfirmation : undefined;
		}
	}
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {InputBase} from '../../models/dynamic-form/input-base.model';
import {ValidatorService} from '../../../shared/services/validator/validator.service';
import {Address} from '../../../features/realty/models/realty.model';
import {ResourceService} from '../../services/resource/resource.service';
import {TranslateService} from '@ngx-translate/core';
import {InputField} from '../../models/dynamic-form/input-types.model';
import {debounceTime, mergeMap, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { PermissionService } from '../../../core/services/permission.service';

@Component({
  selector: 'app-dynamic-form-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.scss'],
})
export class DynamicFormQuestionComponent implements OnInit, OnChanges {
  @Input() question: InputBase<any>;
  @Input() form: FormGroup;
  @Output() valueChange = new EventEmitter();
  @Output() buttonClicked = new EventEmitter();

  @ViewChild('chipInput') chipInput;

  isValid: boolean;

  filteredOptions: Array<any> = [];
  filterFormControl: FormControl = new FormControl();
  searchSubject$: Subject<string> = new Subject();
  @Input() markAsTouched: boolean;

  constructor(
    private validatorService: ValidatorService,
    private resourceService: ResourceService,
    private translateService: TranslateService,
    public permissionService: PermissionService
  ) {
  }

  ngOnInit() {
    this.filteredOptions = this.question['options'] ? this.question['options'].slice() : [];
    if (
      (this.question as InputField).type === 'number' &&
      (this.question as InputField).decimals &&
      this.question.value &&
      typeof this.question.value === 'number'
    ) {
      this.form.controls[this.question.key].setValue(this.question.value.toFixed(2));
    }
    if (this.question['searchCallback']) {
      this.searchSubject$
        .pipe(
          debounceTime(500),
          mergeMap(searchValue => {
            return this.question['searchCallback'](searchValue)   
          }),
          tap((results: Array<any>) => (this.filteredOptions = results))
        )
        .subscribe();
    }
  }

  ngOnChanges(changes) {
    if (changes.markAsTouched && this.markAsTouched) {
      // When updated to angular 8 use https://angular.io/api/forms/AbstractControl#markallastouched
      this.form.controls[this.question.key].markAsTouched();
    }
  }

  getErrors() {
    const errors = this.form.controls[this.question.key].errors;
    if (errors) {
      return this.validatorService.formatValidationErrors(errors, this.question.label);
    }
  }

  onBackspace(event, mask) {
    if (mask) {
      event.preventDefault();
      this.onTextChange(event.target.value, mask, true);
    }
  }

  onBlur() {
    this.isValid = this.form.controls[this.question.key].valid;
    if (!this.filteredOptions.length && this.question['options']) {
      this.filteredOptions = this.question['options'];
    }
  }

  onTextChange(value, mask, backspace) {
    if (this.question.controlType !== 'place') {
      if (mask) {
        const result = mask(value, backspace);
        if (value !== result) {
          this.form.controls[this.question.key].setValue(result);
        }
      }
      this.isValid = this.form.controls[this.question.key].valid;
      this.valueChange.emit();
      if (this.question['searchable']) {
        const search = this.filterFormControl.value;
        if (!search) {
          this.filteredOptions = this.question['options'].slice();
          return;
        }
        if (this.question['searchCallback']) {
          this.searchSubject$.next(value);
        } else {
          this.filteredOptions = this.question['options'].filter(option =>
            this.translateService
              .instant(option.key)
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        }
      }
    } else {
      this.form.controls[this.question.key].setErrors({required: true});
    }
  }

  onSelectionChange(value: string, mask: (value: string, backspace: boolean) => string, backspace: boolean) {
    this.isValid = this.form.controls[this.question.key].valid;
    this.valueChange.emit();
  }

  onAutocompleteSelected(event) {
    const address = this.mapPlaceToString(event.address_components);
    this.form.controls['address'].setValue(address);
    // if (address.zipCode && address.city && address.country) {
    if (address.country) {
      this.form.controls[this.question.key].setErrors(null);
    }
    this.valueChange.emit();
  }

  onButtonClick(value: string, mask: (value: string, backspace: boolean) => string, backspace: boolean) {
    this.buttonClicked.emit();
  }

  mapPlaceToString(place) {
    const address = new Address();

    address.street = this.findComponentTypeInPlaceArray('route', place)
      ? this.findComponentTypeInPlaceArray('route', place).long_name
      : '';
    address.houseNumber = this.findComponentTypeInPlaceArray('street_number', place)
      ? this.findComponentTypeInPlaceArray('street_number', place).long_name
      : '';
    address.zipCode = this.findComponentTypeInPlaceArray('postal_code', place)
      ? this.findComponentTypeInPlaceArray('postal_code', place).long_name
      : '';
    address.city = this.findComponentTypeInPlaceArray('locality', place)
      ? this.findComponentTypeInPlaceArray('locality', place).long_name
      : '';
    address.country = this.findComponentTypeInPlaceArray('country', place)
      ? this.resourceService.getResourceInstance(
      'country',
      'definition',
      this.findComponentTypeInPlaceArray('country', place).short_name
    ) || ''
      : '';

    return address;
  }

  findComponentTypeInPlaceArray(componentType, placeArray) {
    return placeArray.find(component => {
      if (component.types.includes(componentType)) {
        return component;
      } else {
        return '';
      }
    });
  }

  addChip(event, addByBlur = false) {
    // Add chip only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    const input = event.input;
    const value = event.value;

    // Add our chip
    if ((value || '').trim()) {
      this.question.value.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    if (addByBlur) {
      this.chipInput.nativeElement.value = '';
    }

    this.checkChipValidity();
    this.valueChange.emit();
  }

  removeChip(chipItem, index) {
    if (index >= 0) {
      this.question.value.splice(index, 1);
    }
    this.checkChipValidity();
    this.valueChange.emit();
  }

  checkChipValidity() {
    if (!(this.question && this.question.value && this.question.value.length) && this.question.required) {
      this.form.controls[this.question.key].setErrors({required: true});
    } else {
      this.form.controls[this.question.key].setErrors(null);
    }
  }

  public onDateChange($event): void {
    this.form.controls[this.question.key].setValue($event["birthDate"]);
    this.form.controls[this.question.key].markAsTouched()
  }
}

import {Resource} from '../../../shared/models/resource/resource.model';
import {Client} from '../../client/models/client.model';
import {LoanRequest} from '../../loan-request/models/loan-request.model';
import {ThirdParty} from '../../third-party/models/third-party.model';
import {Insurance} from '../../insurance/models/insurance.model';

class MortgageRank {
  creditProvider: Resource;
  id: number;
  amount: number;
  rank: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.creditProvider = new Resource(data.creditProvider);
      this.id = data.id || null;
      this.amount = data.amount || 0;
      this.rank = data.rank || null;
    }
  }
}

export class Renovation {
  renovationType: Resource;
  id: number;
  amountContractor: number;
  amountDiy: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.renovationType = new Resource(data.renovationType);
      this.id = data.id || null;
      this.amountDiy = data.amountDiy || 0;
      this.amountContractor = data.amountContractor || 0;
    }
  }
}

export class BuildCost {
  buildType: Resource;
  id: number;
  amountContractor: number;
  amountDiy: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.buildType = new Resource(data.buildType);
      this.id = data.id || null;
      this.amountDiy = data.amountDiy || 0;
      this.amountContractor = data.amountContractor || 0;
    }
  }
}

class Ownership {
  client: Client;
  id: number;
  ownershipType: Resource;
  percentage: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.client = new Client(data.client);
      this.ownershipType = new Resource(data.ownershipType);
      this.id = data.id || null;
      this.percentage = data.percentage || 0;
    }
  }
}

class LegalEntityOwnership {
  legalEntity: any;
  id: number;
  ownershipType: Resource;
  percentage: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.legalEntity = data.legalEntity;
      this.ownershipType = new Resource(data.ownershipType);
      this.id = data.id || null;
      this.percentage = data.percentage || 0;
    }
  }
}

export class Address {
  street: string;
  houseNumber: string;
  box: string;
  zipCode: string;
  city: string;
  country: Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.street = data.street || '';
      this.houseNumber = data.houseNumber || '';
      this.box = data.box || '';
      this.zipCode = data.zipCode || '';
      this.city = data.city || '';
      this.country = Resource.createResource(data.country);
    }
  }
}

export class Realty {
  acquisitionType: Resource;
  address: Address;
  alreadyEstimated: boolean;
  cadastralIncome: number;
  cadastralLocation: string;
  clients: Array<Ownership>;
  legalEntities: Array<LegalEntityOwnership>;
  energeticRenovations: boolean;
  estimator: ThirdParty;
  estimationDate: Date;
  fireInsurance: Insurance;
  forcedValueAfter: number;
  forcedValueBefore: number;
  housingUnits: number;
  id: number;
  kleinBeschrijf: boolean;
  livingPercentage: number;
  loanRequest: LoanRequest;
  mainFirstResidence: boolean;
  mortgageRanks: Array<MortgageRank>;
  newRealty: Resource;
  purposes: Array<Resource>;
  price: number;
  provideAsCollateral: boolean;
  rank: number;
  realtyType: Resource;
  renovationPrice: number;
  buildPrice: number;
  renovations: Array<Renovation>;
  buildCosts: Array<BuildCost>;
  surface: number;
  usageTypes: Array<Resource>;
  venalValueAfter: number;
  venalValueBefore: number;
  buyoutCohabitation: boolean;
  otherEstimatorName: string;
  epc: number;
  referenceValue: number;
  currentRealEstateGuaranteed: boolean;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.acquisitionType = new Resource(data.acquisitionType);
      this.address = new Address(data.address);
      this.alreadyEstimated = data.alreadyEstimated || false;
      this.cadastralIncome = data.cadastralIncome || 0;
      this.cadastralLocation = data.cadastralLocation || '';
      this.clients = [];
      if (!(data.clients === undefined)) {
        data.clients.forEach(client => {
          this.clients.push(new Ownership(client));
        });
      }
      this.legalEntities = [];
      if (!(data.legalEntities === undefined)) {
        data.legalEntities.forEach(le => {
          this.legalEntities.push(new LegalEntityOwnership(le));
        });
      }
      this.energeticRenovations = data.energeticRenovations !== undefined ? data.energeticRenovations : null;
      this.estimator = ThirdParty.createThirdParty(data.estimator);
      this.estimationDate = data.estimationDate || null;
      this.fireInsurance = new Insurance(data.fireInsurance);
      this.forcedValueAfter = data.forcedValueAfter || 0;
      this.forcedValueBefore = data.forcedValueBefore || 0;
      this.housingUnits = data.housingUnits || 0;
      this.id = data.id || null;
      this.kleinBeschrijf = data.kleinBeschrijf !== undefined ? data.kleinBeschrijf : null;
      this.livingPercentage = data.livingPercentage || 0;
      this.loanRequest = data.loanRequest || null;
      this.mainFirstResidence = data.mainFirstResidence !== undefined ? data.mainFirstResidence : null;
      this.mortgageRanks = [];
      if (!(data.mortgageRanks === undefined)) {
        data.mortgageRanks.forEach(mortgageRank => {
          this.mortgageRanks.push(new MortgageRank(mortgageRank));
        });
      }
      this.renovations = [];
      if (!(data.renovations === undefined)) {
        data.renovations.forEach(renovation => {
          this.renovations.push(new Renovation(renovation));
        });
      }
      this.buildCosts =[];
      if (!(data.buildCosts === undefined)) {
        data.buildCosts.forEach(buildCost => {
          this.buildCosts.push(new BuildCost(buildCost));
        });
      }
      this.newRealty = new Resource(data.newRealty);
      this.purposes = [];
      if (!(data.purposes === undefined)) {
        data.purposes.forEach(purpose => {
          this.purposes.push(new Resource(purpose));
        });
      }
      this.price = data.price || 0;
      this.provideAsCollateral = data.provideAsCollateral || false;
      this.rank = data.rank || null;
      this.realtyType = new Resource(data.realtyType);
      this.renovationPrice = data.renovationPrice || 0;
      this.buildPrice = data.buildPrice || 0;
      this.surface = data.surface || 0;
      this.usageTypes = [];
      if (!(data.usageTypes === undefined)) {
        data.usageTypes.forEach(usage => {
          this.usageTypes.push(new Resource(usage));
        });
      }
      this.venalValueAfter = data.venalValueAfter || 0;
      this.venalValueBefore = data.venalValueBefore || 0;
      this.buyoutCohabitation = data.buyoutCohabitation !== undefined ? data.buyoutCohabitation : null;
      this.currentRealEstateGuaranteed = data.currentRealEstateGuaranteed !== undefined ? data.currentRealEstateGuaranteed : null;
      this.otherEstimatorName = data.otherEstimatorName || '';
      this.epc = data.epc || 0;
      this.referenceValue = data.referenceValue || 0;
    }
  }
}

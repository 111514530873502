import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { CobrokerPopupComponent } from '../../../features/broker/components/cobroker-popup/cobroker-popup.component';
import {LiabilityComponent} from '../../../features/client/liability/components/liability/liability.component';
import { LoanRequestDialogComponent } from '../../../features/loan-request/components/loan-request-dialog/loan-request-dialog.component';
import { NewsflashAttatchFilesPopupComponent } from '../../../features/newsflash/components/newsflash-attatch-files-popup/newsflash-attatch-files-popup.component';
import { NewsflashPopupComponent } from '../../../features/newsflash/components/newsflash-popup/newsflash-popup.component';
import {DialogComponent} from '../../components/dialog/dialog.component';
import { OwnFundsDialogComponent } from '../../components/own-funds-dialog/own-funds-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {
  }

  open(data = {}, hasBackdrop = true, width = '800px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    const dialogRef = this.openBase(data, hasBackdrop, width, maxWidth, maxHeight, restoreFocus);
    return dialogRef.afterClosed();
  }

  openBase(data = {}, hasBackdrop = true, width = '800px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    return this.dialog.open(DialogComponent, {
      data,
      hasBackdrop,
      width,
      maxWidth,
      maxHeight,
      restoreFocus,
    });
  }

  openLiability(data = {}, hasBackdrop = true, width = '800px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    return this.dialog.open(LiabilityComponent, {
      data,
      hasBackdrop,
      width,
      maxWidth,
      maxHeight,
      restoreFocus,
    });
  }

  openNewsflash(data = {}, hasBackdrop = true, width = '1000px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    return this.dialog.open(NewsflashPopupComponent, {
      data,
      hasBackdrop,
      width,
      maxWidth,
      maxHeight,
      restoreFocus,
    });
  }

  openNewsflashAttachFiles(data = {}, hasBackdrop = true, width = '1000px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    return this.dialog.open(NewsflashAttatchFilesPopupComponent, {
      data,
      hasBackdrop,
      width,
      maxWidth,
      maxHeight,
      restoreFocus,
    });
  }

  openLoanRequestDialogComponent(data = {}, hasBackdrop = true, width = '1000px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    return this.dialog.open(LoanRequestDialogComponent, {
      data,
      hasBackdrop,
      width,
      maxWidth,
      maxHeight,
      restoreFocus,
    });
  }

  openCobrokerDialogComponent(data = {}, hasBackdrop = true, width = '1000px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    return this.dialog.open(CobrokerPopupComponent, {
      data,
      hasBackdrop,
      width,
      maxWidth,
      maxHeight,
      restoreFocus,
    })
  }

  openOwnFundsDialog(data = {}, hasBackdrop = true, width = '1000px', maxWidth = '96vw', maxHeight = '88vh', restoreFocus = true) {
    return this.dialog.open(OwnFundsDialogComponent, {
      data,
      hasBackdrop,
      width,
      maxWidth,
      maxHeight,
      restoreFocus,
    })
  }

  close() {
    this.dialog.closeAll();
  }
}

import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../../shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {
  }

  public open(message: string, type: string = '', action: string = null, duration: number = null, options: MatSnackBarConfig = null) {
    if (!options) {
      options = {};
    }
    if (!duration) {
      duration = 5000;
    }
    options.duration = duration;
    if (!options.verticalPosition) {
      options.verticalPosition = 'top';
    }
    // Data for the component
    options.data = {
      message: message,
      action: action,
      class: type || 'success',
    };
    // Modifying the background of the base snackbar. See styles.css
    options.panelClass = ['snackbar'];
    this.snackBar.openFromComponent(SnackbarComponent, options);
  }
}

import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, throwError, timer} from 'rxjs';
import {catchError, flatMap, map, mergeMap, switchMap} from 'rxjs/operators';

import {CUSTOMER_INSIGHTS_CONFIG, CustomerInsights} from '@oper-brokerage-v1/shared/configuration';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly API_VERSION = '1.0';

  constructor(private httpClient: HttpClient, @Inject(CUSTOMER_INSIGHTS_CONFIG) private customerInsights: CustomerInsights) {
  }

  private prepareHeaders(headers: HttpHeaders | null | undefined) {
    if (!headers) {
      headers = new HttpHeaders();
    }
    if (!headers.has('Content-Type')) {
      // We need to default this else we get 415 errors
      headers = headers.set('Content-Type', 'application/json');
    } else if (headers.get('Content-type').toLowerCase() === 'multipart/form-data') {
      // We hope that Angular is smart enough to find the correct content type and then set the boundary
      // If we just set multipart/form-data, we don't set the boundary and Django screws up
      // Solves https://sentry.io/organizations/oper-engineering-yq/issues/1636963366/?project=1779687 if application/json is set / We don't set content type
      // And https://sentry.io/organizations/oper-engineering-yq/issues/1637253775/?project=1761984&query=is%3Aunresolved if multipart/form-data is set manually without a boundary
      headers = headers.delete('Content-type');
    }
    if (!headers.has('Accept')) {
      headers = headers.set('Accept', `*/*; version=${this.API_VERSION}`);
    } else {
      if (!headers.get('Accept').includes('version')) {
        // Add '; version=1.0' to each part of the accept
        headers = headers.set(
          'Accept',
          headers
            .get('Accept')
            .split(',')
            .map(type => `${type}; version=${this.API_VERSION}`)
            .join(',')
        );
      }
    }
    return headers;
  }

  poolSize = 4

  public toPool(func: () => Observable<any>): Observable<any> {
    // if (this.poolSize <= 0) {
    //   return timer(20).pipe(mergeMap(time => this.toPool(func)))
    // } else {
    //   this.poolSize --
      return func().pipe(map(result => {
          this.poolSize ++
          return result
        }
      ))
        .pipe(catchError(this.formatErrors))
  //  }
  }

  public get(path: string, params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    headers = this.prepareHeaders(headers);
    return this.toPool(() =>
      this.httpClient.get(this.customerInsights.serverUrl + path, {
        params,
        headers
      })
    );
  }

  public download(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    const options: {
      responseType: 'text';
      params?: HttpParams;
    } = {
      responseType: 'text',
      params: params
    };
    return this.toPool(() =>
      this.httpClient.get(this.customerInsights.serverUrl + path, options)
    );
  }

  public put(path: string, body: object = {}, options = null): Observable<any> {
    if (!options) {
      options = {};
    }
    options.headers = this.prepareHeaders(options.headers);
    return this.httpClient.put(this.customerInsights.serverUrl + path, body, options).pipe(catchError(this.formatErrors));
  }

  public patch(path: string, body: object = {}, options = null): Observable<any> {
    if (!options) {
      options = {};
    }
    options.headers = this.prepareHeaders(options.headers);
    return this.toPool(() =>
      this.httpClient.patch(this.customerInsights.serverUrl + path, body, options)
    )
  }

  public post(path: string, body: object = {}, options = null): Observable<any> {
    if (!options) {
      options = {};
    }
    options.headers = this.prepareHeaders(options.headers);
    return this.httpClient.post(this.customerInsights.serverUrl + path, body, options).pipe(catchError(this.formatErrors));
  }

  public delete(path: string, headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    headers = this.prepareHeaders(headers);
    return this.httpClient.delete(this.customerInsights.serverUrl + path, {headers}).pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error);
  }
}

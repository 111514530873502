import {InputBase} from './input-base.model';
import {COMMA, DASH, ENTER, SPACE, TAB} from '@angular/cdk/keycodes';

export class InputField extends InputBase<string> {
  controlType = 'field';
  mask?: (value: string, backspace: boolean) => string;
  type?: string;
  decimals: boolean;
  multiline = false;

  constructor(options: {} = {}) {
    super(options);
    this.mask = options['mask'] || '';
    this.type = options['type'] || '';
    this.decimals = options['decimals'] === undefined ? true : options['decimals'];
    this.multiline = options['multiline'] || false;
  }
}

export class InputChipItems extends InputBase<Array<string>> {
  controlType = 'chips';
  type?: string;
  removable = true;
  selectable = true;
  separators: Array<any> = [SPACE, ENTER, COMMA, TAB, DASH];

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    if (options['removable'] !== undefined) {
      this.removable = options['removable'];
    }
    if (options['selectable'] !== undefined) {
      this.removable = options['selectable'];
    }
  }
}

export class DateInput extends InputBase<Array<string>> {
  controlType = 'date';
  type?: string;
  formats?: string[];
  searchable = false;
  searchCallback = null;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.formats = options['formats'] || [];
    if (options['searchable'] !== undefined) {
      this.searchable = options['searchable'];
    }
    this.searchCallback = options['searchCallback'] || null;
  }
}

export class InputSubmit extends InputBase<string> {
  controlType = 'submit';
  iconName: string;

  constructor(options: {} = {}) {
    super(options);
    this.iconName = options['iconName'] || '';
  }
}

export class InputSelect extends InputBase<string> {
  controlType = 'select';
  options: { id: number; definition: string }[] = [];
  searchable = false;
  searchCallback = null;

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    if (options['searchable'] !== undefined) {
      this.searchable = options['searchable'];
    }
    this.searchCallback = options['searchCallback'] || null;
  }
}

export class InputPlace extends InputBase<string> {
  controlType = 'place';

  constructor(options: {} = {}) {
    super(options);
  }
}

export class Spacer extends InputBase<string> {
  controlType = 'spacer';

  constructor(options: {} = {}) {
    super(options);
    this.visible = false;
    this.key = options['key'] || 'spacer';
  }
}

export class Divider extends InputBase<string> {
  controlType = 'divider';

  constructor(options: {} = {}) {
    super(options);
  }
}

<div class="view">
	<div class="conteinerNews">
		<div class="list-header">
			<div class="column title">
				<b>{{ 'ç.feature.newsflash.titleAttatchFiles' | translate }}</b>
			</div>
			<div class="action">
				<i class="uil uil-multiply" (click)="dialogRef.close()"></i>
			</div>
		</div>
	</div>
	<mat-card>
		<div class="conteinerNews">
			<div class="list-header">
				<div class="column title">
					<b>{{ 'ç.feature.newsflash.attatchedFilesNL' | translate }}</b>
				</div>
				<div class="column title">
					<b>{{ 'ç.feature.newsflash.attatchedFilesFR' | translate }}</b>
				</div>
			</div>
			<div class="list-body">
				<div class="column title">
					<button (click)="uploadDocument('nl')">
						<i class="uil uil-upload"></i>
						{{ 'ç.feature.document.uploadDocumentNL' | translate }}
					</button>
					<ng-container *ngFor="let file of filesNL">
						<div class="file">
							<p>{{file.key}}</p>
							<button class="delete" (click)="deleteDocument(file.id)">
								<i class="uil uil-trash-alt warn"></i>
							</button>
						</div>
					</ng-container>
				</div>
				<div class="column title">
					<button (click)="uploadDocument('fr')">
						<i class="uil uil-upload"></i>
						{{ 'ç.feature.document.uploadDocumentFR' | translate }}
					</button>
					<ng-container *ngFor="let file of filesFR">
						<div class="file">
							<p>{{file.key}}</p>
							<button class="delete" (click)="deleteDocument(file.id)">
								<i class="uil uil-trash-alt warn"></i>
							</button>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</mat-card>
</div>

import {Resource} from '../../../../shared/models/resource/resource.model';

class Address {
  street: string;
  houseNumber: string;
  box: string;
  zipCode: string;
  city: string;
  country: Resource;
  addressType: Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.street = data.street || '';
      this.houseNumber = data.houseNumber || '';
      this.box = data.box || '';
      this.zipCode = data.zipCode || '';
      this.city = data.city || '';
      this.country = Resource.createResource(data.country);
      this.addressType = Resource.createResource(data.addressType);
    }
  }
}

export class Firm {
  name: string;
  sector: Resource;
  vatNumber: string;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.sector = new Resource(data.sector);
      this.name = data.name || '';
      this.vatNumber = data.vatNumber || '';
    }
  }
}

export class Income {
  id?: number;
  amount?: number;
  contractType?: Resource;
  employer?: Firm;
  employmentType?: Resource;
  incomeType?: Resource;
  jobFunction?: string;
  periodicity?: Resource;
  startDate?: string;
  wageGarnishment?: boolean;
  ages?: Array<number>;
  company?: Firm;
  comment?: string;
  monthlyAmount?: string;
  country?: Resource;
  monthlyAmountWeighted?: number;

  monthlyAmountWeightedDti?: number;
  payer: string;
  is_66Present?: boolean;
  bruttoAmount?: number;
  monthlyBruttoAmountWeighted?: number;
  monthlyBruttoAmountWeightedDti?: number;
  isHolidayPayIncluded?: boolean;
  isDecrease?: boolean;
  rentalType?:Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.amount = data.amount || 0;
      this.contractType = Resource.createResource(data.contractType);
      this.employmentType = Resource.createResource(data.employmentType);
      this.incomeType = Resource.createResource(data.incomeType);
      this.jobFunction = data.jobFunction || '';
      this.periodicity = Resource.createResource(data.periodicity);
      this.startDate = data.startDate || null;
      if (!(data.wageGarnishment === undefined || data.wageGarnishment === null)) {
        this.wageGarnishment = data.wageGarnishment;
      }
      this.ages = data.ages === undefined ? '' : data.ages;
      this.comment = data.comment || '';
      this.monthlyAmount = data.monthlyAmount || 0;
      this.monthlyAmountWeighted = data.monthlyAmountWeighted || 0;
      this.monthlyAmountWeightedDti = data.monthlyAmountWeightedDti || this.monthlyAmountWeighted;
      if (data.address && data.incomeType && data.incomeType.definition === 'salaryEmployed') {
        this.employer = new Firm({...data.employer});
      } else {
        this.employer = new Firm(data.employer);
      }
      if (data.address && data.incomeType && data.incomeType.definition === 'salarySelfEmployed') {
        this.company = new Firm({...data.company});
      } else {
        this.company = new Firm(data.company);
      }
      this.country = Resource.createResource(data.country);
      this.payer = data.payer || '';
      if (!(data.is66Present === undefined || data.is66Present === null)) {
        this.is_66Present = data.is66Present;
      }
      if (!(data.is_66Present === undefined || data.is_66Present === null)) {
        this.is_66Present = data.is_66Present;
      }
      this.bruttoAmount = data.bruttoAmount || 0;
      this.monthlyBruttoAmountWeighted = data.monthlyBruttoAmountWeighted || 0;
      this.monthlyBruttoAmountWeightedDti = data.monthlyBruttoAmountWeightedDti || this.monthlyBruttoAmountWeighted;
      if (!(data.isHolidayPayIncluded === undefined || data.isHolidayPayIncluded === null)) {
        this.isHolidayPayIncluded = data.isHolidayPayIncluded;
      }
      if (!(data.isDecrease === undefined || data.isDecrease === null)) {
        this.isDecrease = data.isDecrease;
      }
      this.rentalType = Resource.createResource(data.rentalType);
    }
  }
}

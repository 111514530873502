import {FormConfiguration} from '../dynamic-form/dynamic-form.model';

class Buttons {
  cancel: string;
  accept: string;

  constructor(data) {
    this.cancel = (data && data.cancel) || 'ç.misc.action.cancel';
    this.accept = (data && data.accept) || 'ç.misc.action.next';
  }
}

class ChangeConfiguration {
  title: string;
  callBack: (event?: any) => void;
}

export class DialogData {
  title: string;
  description: string;
  formConfiguration: FormConfiguration;
  buttons: Buttons;
  warn: boolean;
  startAsValid: boolean;
  changeConfiguration: ChangeConfiguration;
  isShouldUseBottons: boolean;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.title = data.title || '';
      this.description = data.description || '';
      this.formConfiguration = data.formConfiguration || null;
      this.buttons = new Buttons(data.buttons || null);
      this.warn = data.warn;
      this.startAsValid = data.startAsValid === false ? false : true; // Default to true
      this.changeConfiguration = data.changeConfiguration;
      this.isShouldUseBottons = data.isShouldUseBottons === false ? false : true; // Default to true
    }
  }

  init(data?: any) {
    this.title = data.title || '';
    this.description = data.description || '';
    this.buttons = new Buttons(data.buttons || null);
    this.warn = data.warn;
    this.startAsValid = data.startAsValid === false ? false : true; // Default to true
    this.changeConfiguration = data.changeConfiguration;
  }
}

export class ConfirmationDialogData {
  translationPath: string;
  warn: boolean;
  isOnlyOneButton?: boolean;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.translationPath = data.translationPath || '';
      this.warn = data.warn;
      this.isOnlyOneButton = data.isOnlyOneButton || false;
    }
  }
}

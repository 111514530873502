import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  snackbarClass: string;
  message: string;
  action: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackBarRef: MatSnackBarRef<SnackbarComponent>) {
    this.snackbarClass = data.class || 'success';
    this.message = data.message || '';
    this.action = data.action || 'ç.misc.ok';
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }
}

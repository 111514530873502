import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlags: { [key: string]: boolean } = {};

  constructor(private apiService: ApiService) {
  }

  public initFeatureFlags() {
    return this.apiService.get('/api/features/').pipe(tap(featureFlags => (this.featureFlags = featureFlags)));
  }

  public hasFeatureFlag(featureName: string): boolean {
    return Object.keys(this.featureFlags).includes(featureName) && this.featureFlags[featureName];
  }
}

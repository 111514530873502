import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { PermissionService } from 'apps/brokerage-v1/src/app/core/services/permission.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnDestroy {
  @Input() question: any;
  @Input() form: FormGroup;
  @Output() valueChange = new EventEmitter();

  date: Date;
  _day: string;
  _month: string;
  _year: string;

  days: string[] = [];
  months: string[] = [];
  years: string[] = [];

  filteredDays: string[] = [];
  filteredMonths: string[] = [];
  filteredYears: string[] = [];
  nationalNumberRegexBE = /(\d{2})\.(\d{2})\.(\d{2})-\d{3}.\d{2}/;
  private destroy$ = new Subject<void>();

  filterFormControl: FormControl = new FormControl();

  tabindex;

  constructor(
    public permissionService: PermissionService
  ) {
  }

  ngOnInit(): void {
    this.tabindex = this.permissionService.hasPermission('main.auditorExcludeEditing') ? -1 : null;
    this.setAttributes();
    this.populatedDropdowns();
    this.form.get("nationalNumber").valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(nationalNumber => {
        this.setAttributes(nationalNumber)
      })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onMonthChange(): void {
    const date = new Date(Number(this.year), Number(this.month) - 1);
    this.populateDays(date);
    this.setQuestionDateValue();
  }

  public onYearChange(): void {
    let date = new Date(Number(this.year));
    if (this.month) {
      date = new Date(Number(this.year), Number(this.month) - 1);
    }
    this.populateDays(date);
    this.setQuestionDateValue();
  }

  public onDayChange(): void {
    this.setQuestionDateValue();
  }

  public filterDays(value: string): void {
    this.filteredDays = this.days.filter(option => option.startsWith(value));
  }

  public filterMonths(value: string): void {
    this.filteredMonths = this.months.filter(option => option.startsWith(value));
  }

  public filterYears(value: string): void {
    this.filteredYears = this.years.filter(option => option.startsWith(value));
  }

  get day(): string {
    return this._day;
  }

  set day(value: string) {
    const temp = Number(value);
    if (temp < 10) {
      this._day = `${"0"}${temp.toString()}`;
    } else {
      this._day = temp.toString();
    }
  }

  get month(): string {
    return this._month;
  }

  set month(value: string) {
    const temp = Number(value);
    if (temp < 10) {
      this._month = `${"0"}${(temp).toString()}`;
    } else {
      this._month = (temp).toString();
    }
  }

  get year(): string {
    return this._year;
  }

  set year(value: string) {
    this._year = value;
  }

  private setQuestionDateValue(): void {
    let value = "";
    if (this.year) {
      value += this.year;
    }
    if (this.month) {
      value += `${"-"}${this.month}`;
    }
    if (this.day && this.day !== '00' && this.month) {
      value += `${"-"}${this.day}`;
    }
    this.question.value = value;
    this.form.value["birthDate"] = value;
    this.valueChange.emit(this.form.value);
  }

  private setAttributes(nationalNumber?: string): void {
    if (this.question.value !== "") {
      const values: string[] = this.question.value.split('-');
      this.year = values[0];
      if (this.year) {
        this.date = new Date(Number(this.year));
        if (values.length === 2) {
          this.month = values[1];
          this.date = new Date(Number(this.year), Number(this.month) - 1);
        }
        if (values.length === 3) {
          this.month = values[1];
          this.day = values[2];
          this.date = new Date(Number(this.year), Number(this.month) - 1, Number(this.day));
        }
      }
    } else if (this.nationalNumberRegexBE.test(nationalNumber)) {
      this.setDateFromNationalNumber(nationalNumber);
      this.populateDays(this.date);
    } else {
      this.date = new Date();
    }
  }

  private setDateFromNationalNumber(nationalNumber: string): void {
    const groups = nationalNumber.match(this.nationalNumberRegexBE);
    const yearOffset = groups[1];
    this.year = parseInt(yearOffset, 10) <= new Date().getFullYear() % 100 ? `20${yearOffset}` : `19${yearOffset}`;
    this.date = new Date(Number(this.year));
    if (Number(groups[2]) !== 0 && Number(groups[2]) <= 12) {
      this.month = groups[2];
      this.date = new Date(Number(this.year), Number(this.month) - 1);
      if (Number(groups[3]) !== 0 && Number(groups[2]) <= 31) {
        this.day = groups[3];
        this.date = new Date(Number(this.year), Number(this.month) - 1, Number(this.day));
      }
    }
  }

  private populateDays(date: Date): void {
    this.days = [];
    const countDaysInMonth = moment(date).daysInMonth();
    for (let i = 1; i <= countDaysInMonth; i++) {
      if (i < 10) {
        this.days.push("0" + (i).toString());
      } else {
        this.days.push((i).toString());
      }
    }
    if (Number(this.day) > countDaysInMonth) {
      this.day = null;
    }
    this.filteredDays = this.days;
  }

  private populateMonths(): void {
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        this.months.push("0" + i.toString());
      } else {
        this.months.push(i.toString());
      }
    }
  }

  private populateYears(): void {
    const date: Date = new Date();
    for (let i = date.getFullYear(); i >= 1900; i--) {
      this.years.push(i.toString());
    }
  }

  private populatedDropdowns(): void {
    this.populateDays(this.date);
    this.populateMonths();
    this.populateYears();
    this.filteredMonths = this.months;
    this.filteredYears = this.years;
  }
}

import {Component, DoCheck, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ValidatorService} from '../../services/validator/validator.service';
import {FormConfiguration} from '../../models/dynamic-form/dynamic-form.model';
import {FormWarningService} from '../../services/form-warning/form-warning.service';
import {FormGroupWithWarning} from '../../models/form-warnings/form-warning.model';
import { PermissionService } from '../../../core/services/permission.service';

// tslint:disable-next-line: no-conflicting-lifecycle
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnChanges, DoCheck {
  @Input() formConfiguration: FormConfiguration;
  @Input() markAllAsTouched: boolean;
  form: FormGroupWithWarning;
  @Output() valueChange = new EventEmitter();
  @Output() buttonClicked = new EventEmitter();
  @Output() valueInit = new EventEmitter();
  @Output() errors = new EventEmitter();
  @Output() warnings = new EventEmitter();
  triggerMarkAsTouched = false;
  previousErrors: ValidationErrors = null;
  previousWarnings: ValidationErrors = null;

  constructor(
    private validatorService: ValidatorService, 
    private formWarningService: FormWarningService,
    public permissionService: PermissionService
    ) {
  }

  ngOnChanges(changes) {
    if (changes.formConfiguration) {
      this.form = this.toFormGroup(changes.formConfiguration.currentValue);
      this.getErrors();
      this.getWarnings();
    }
    if (changes.formConfiguration && changes.formConfiguration.currentValue) {
      this.valueInit.emit(this.form);
    }
    if (changes.markAllAsTouched && this.markAllAsTouched) {
      this.triggerMarkAsTouched = true;
    }
  }

  ngDoCheck() {
    /**
     * //TODO refactor error/warning handling
     * Errors and warnings are not ready at form construction as we as on AfterViewInit hook.
     * So we have to checking errors on DoCheck lifecycle hook.
     * It causes ExpressionChangedAfterItHasBeenCheckedError warning in DEV environment
     */
    if (
      (this.form?.warnings && Object.keys(this.form?.warnings).length) !==
      (this.previousWarnings && Object.keys(this.previousWarnings).length)
    ) {
      this.getWarnings();
    }
    if (
      (this.form?.errors && Object.keys(this.form?.errors).length) !==
      (this.previousErrors && Object.keys(this.previousErrors).length)
    ) {
      this.getErrors();
    }
  }

  getErrors() {
    this.previousErrors = this.form?.errors && {...this.form?.errors} || null;
    if (this.previousErrors) {
      const formattedErrors = this.validatorService.formatValidationErrors(this.previousErrors);
      this.errors.emit(formattedErrors);
    } else {
      this.errors.emit([]);
    }
  }

  getWarnings() {
    this.previousWarnings = this.form?.warnings && {...this.form?.warnings} || null;
    if (Object.keys(this.previousWarnings).length > 0) {
      const formattedWarnings = this.formWarningService.formatWarnings(this.previousWarnings);
      this.warnings.emit(formattedWarnings);
    } else {
      this.warnings.emit([]);
    }
  }

  toFormGroup(formConfiguration: FormConfiguration) {
    const group: any = {};
    let formGroupValidators: Array<ValidatorFn> = [];
    let formGroupAsyncValidators: Array<AsyncValidatorFn> = [];
    if (formConfiguration) {
      if (formConfiguration.formControl && formConfiguration.formControl.questions) {
        const questions = formConfiguration.formControl.questions;
        questions.forEach(question => {
          if (typeof question.value === 'object') {
            // TODO Arno and Nick: Discuss a proper way of handling this
            // Arno: "Why do we need to do this?""
            // Nick: The value of the question can be an object, a boolean or a number.
            // Simply checking question.value || does not handle athat well.
            // group[question.key] = new FormControl(question.value['id'] || '', question.validators, question.asyncValidator);
            group[question.key] = new FormControl(question.value || '', question.validators, question.asyncValidator);
            // Arno: if question.value['id'] instead of question.value, values of multi select are not being set
          } else if (typeof question.value === 'boolean') {
            group[question.key] = new FormControl(question.value, question.validators, question.asyncValidator);
          } else {
            group[question.key] = new FormControl(question.value || '', question.validators, question.asyncValidator);
          }
        });
      }
      if (formConfiguration.formGroup && formConfiguration.formGroup.validators) {
        formGroupValidators = formConfiguration.formGroup.validators;
      }
      if (formConfiguration.formGroup && formConfiguration.formGroup.asyncValidators) {
        formGroupAsyncValidators = formConfiguration.formGroup.asyncValidators;
        // If we have sync validators, we convert the sync ones to async so they play well together
      }
    }
    return new FormGroupWithWarning(group, {
      validators: formGroupValidators,
      asyncValidators: formGroupAsyncValidators,
    });
  }

  valueChanged() {
    this.valueChange.emit(this.form);
    this.getErrors();
    this.getWarnings();
  }

  onClick() {
    this.buttonClicked.emit();
  }
}

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {APP_CONFIG, CORE_INFORMATION} from '@oper-brokerage-v1/shared/configuration';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

declare const SERVER_URL: string;
declare const COMMIT_HASH: string;

fetch(`/assets/${COMMIT_HASH}/config/customer.json`)
  .then(response => response.json())
  .then(config => {
    const enhancedConfig = {
      applicationInsights: config.applicationInsights,
      customerInsights: {
        ...config.customerInsights,
        ...(typeof SERVER_URL !== 'undefined' ? {serverUrl: SERVER_URL} : {}),
      },
    };

    if (environment.production) {
      enableProdMode();
    }

    fetch(`${enhancedConfig.customerInsights.serverUrl}/api/health/`)
      .then(response => response.json())
      .then(info => {
        platformBrowserDynamic([
          {provide: CORE_INFORMATION, useValue: info},
          {provide: APP_CONFIG, useValue: enhancedConfig},
        ])
          .bootstrapModule(AppModule)
          .catch(err => console.error(err));
      });
  });

<div class="view">
	<mat-card>
		<div class="conteinerNews">
			<div class="list-header">
				<div class="column">
					<b>{{ title }}</b>
				</div>
				<div class="action">
					<i class="uil uil-multiply" (click)="dialogRef.close()"></i>
				</div>
			</div>
			<div class="newsStatement">
				<div class="column" *ngFor="let body of bodyParagrafs">
					<p>{{ body }}</p>
				</div>
			</div>
		</div>
	</mat-card>
	<mat-card class="mat-card">
		<div class="column">
			<button mat-flat-button *ngIf="dialogData.files.length > 0" (click)="viewDocument()">
				<i class="uil uil-eye"></i>
				<span> {{ 'ç.feature.document.viewDocument' | translate }}</span>
			</button>
		</div>
		<div class="column confirm">
			<button mat-flat-button 
					color="primary" 
					(click)="confirmNewByClick()" 
					*ngIf="dialogData.confirmationFlag"
					[disabled]="dialogData.confirmed">
				<i class="uil uil-newspaper"></i>
				<span> {{ 'ç.misc.action.confirm' | translate }}</span>
			</button>
		</div>
	</mat-card>
</div>

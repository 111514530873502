import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'apps/brokerage-v1/src/app/core/services/snackbar.service';
import { DialogComponent } from 'apps/brokerage-v1/src/app/shared/components/dialog/dialog.component';
import { DialogData } from 'apps/brokerage-v1/src/app/shared/models/dialog/dialog.model';
import { FormConfiguration } from 'apps/brokerage-v1/src/app/shared/models/dynamic-form/dynamic-form.model';
import { Broker } from '../../models/broker.model';
import { BrokerService } from '../../services/broker.service';

@Component({
	selector: 'app-cobroker-popup',
	templateUrl: './cobroker-popup.component.html',
	styleUrls: ['./cobroker-popup.component.scss'],
})
export class CobrokerPopupComponent implements OnInit {
	idBroker: number;
	broker: Broker;
	allBrokers = [];
	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public dialogRef: MatDialogRef<CobrokerPopupComponent>,
		public dialog: MatDialog,
		public brokerService: BrokerService,
		public snackBarService: SnackBarService
	) {
		this.idBroker = this.dialogData.idBroker;
	}

	ngOnInit() {
		this.brokerService.getBrokers({ page_size: 99999, is_co_broker: true }).subscribe((result) => {
			this.allBrokers = result.results.map((broker) => {
				return {
					id: broker.id,
					key: broker.name,
				};
			});
		});
		this.getBroker();
	}

	getBroker() {
		this.brokerService.getBroker(this.idBroker).subscribe((result) => {
			this.broker = result;
		});
	}

	createCobroker() {
		const question = this.brokerService.getCobrokerQuestion(this.allBrokers);
		const dialogData = new DialogData({
			title: 'ç.feature.thirdParty.broker.form.createCobroker',
			description: '',
			buttons: {
				accept: 'ç.misc.action.save',
			},
			formConfiguration: new FormConfiguration(),
		});
		dialogData.formConfiguration.formControl.questions = question;
		this.dialog
			.open(DialogComponent, {
				data: dialogData,
				hasBackdrop: true,
				width: '800px',
				maxWidth: '96vw',
				maxHeight: '88vh',
				restoreFocus: true,
			})
			.afterClosed()
			.subscribe((result) => {
				if (result?.value) {
					const body = {
						brokerFrom: this.idBroker,
						brokerTo: result.value.coBroker,
					};
					this.brokerService.createCobrokers(body).subscribe(
						() => {
							this.getBroker();
							this.snackBarService.open('ç.userFeedback.creatingCobroker.success');
						},
						(error) => {
							this.snackBarService.open('ç.userFeedback.creatingCobroker.error');
						}
					);
				}
			});
	}

	getCobroker(id) {
		return this.allBrokers.find((broker) => broker.id == id);
	}

	deleteCobroker(id) {
		this.brokerService
			.deleteCobrokers({
				brokerFrom: this.idBroker,
				brokerTo: id,
			})
			.subscribe(
				() => {
					this.getBroker();
					this.snackBarService.open('ç.userFeedback.deleteCobroker.success');
				},
				(error) => {
					this.snackBarService.open('ç.userFeedback.deleteCobroker.error');
				}
			);
	}
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
	selector: 'app-document-newsflash-popup',
	templateUrl: './document-newsflash-popup.component.html',
	styleUrls: ['./document-newsflash-popup.component.scss'],
})
export class DocumentNewsflashPopupComponent {
	dataFiles: any;
	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public dialogRef: MatDialogRef<DocumentNewsflashPopupComponent>,
		private domSanitizer: DomSanitizer
	) {
		this.dataFiles = this.dialogData.files.map((dFile) => {
			let type;
			if (dFile.mimeType.includes('pdf')) {
				type = 'pdf';
			} else if (dFile.mimeType.includes('image')) {
				type = 'image';
			}
			return {
				file: this.domSanitizer.bypassSecurityTrustResourceUrl(dFile.link),
				documentMimeType: type,
				link: dFile.link,
			};
		});
	}
}

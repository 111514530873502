import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  public set(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  public get(key: string) {
    return sessionStorage.getItem(key);
  }

  public unset(key: string) {
    sessionStorage.removeItem(key);
  }

  public clear() {
    sessionStorage.clear();
  }
}

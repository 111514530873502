import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../models/dialog/dialog.model';
import {FormGroup} from '@angular/forms';
import { PermissionService } from '../../../core/services/permission.service';

@Component({
  selector: 'app-own-funds-dialog',
  templateUrl: './own-funds-dialog.component.html',
  styleUrls: ['./own-funds-dialog.component.scss'],
})
export class OwnFundsDialogComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter<any>();
  formConfiguration;
  isDisabled: boolean;
  form: FormGroup;
  formConfigurationErrors: Array<string> = [];
  formConfigurationWarnings: Array<string> = [];
  currentSumOwnFunds: number = 0;
  ownFundsTotal: number = 0;
  leftOwnFunds: number = 0;
  constructor(
    public dialogRef: MatDialogRef<OwnFundsDialogComponent>, 
    public permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA) public dialogData
    ) {
  }

  ngOnInit() {
    this.currentSumOwnFunds = this.dialogData.baseSumOwnFunds;
    this.ownFundsTotal = this.dialogData.ownFundsTotal;
    this.leftOwnFunds = this.ownFundsTotal - this.currentSumOwnFunds;
    this.isDisabled = !(this.dialogData.startAsValid || false);
    this.dialogData = new DialogData(this.dialogData);
    this.fillForm();
  }

  fillForm() {
    this.formConfiguration = this.dialogData.formConfiguration;
    // this.questions = this.dynamicForm.create(); // INSERT WHEN SERVICE IS AVAILABLE
    if (this.formConfiguration === undefined || this.formConfiguration === null) {
      this.isDisabled = false;
      this.form = new FormGroup({});
    }
  }

  getFormConfigurationErrors(errors) {
    this.formConfigurationErrors = errors;
  }

  getFormConfigurationWarnings(warnings) {
    this.formConfigurationWarnings = warnings;
  }

  formValueChanged(form) {
    let keys = Object.keys(form.value).filter(key => key.endsWith('Amount'));
      let totalOwnFunds = 0;
      keys.forEach(key => {
        totalOwnFunds += +(form.value[key]);
      })
    this.currentSumOwnFunds = totalOwnFunds;
    this.leftOwnFunds = this.ownFundsTotal - this.currentSumOwnFunds;
    this.form = form;
    this.isDisabled = !this.form.valid;
  }

  formValueInit(form) {
    this.form = form;
    this.isDisabled = !this.form.valid;
  }

  onSubmit() {
    this.dialogRef.close(this.form);
  }

  onButtonClicked() {
    this.buttonClicked.emit(this.form);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'apps/brokerage-v1/src/app/core/services/snackbar.service';
import { BottomSheetComponent } from 'apps/brokerage-v1/src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { EMPTY, forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NewsflashService } from '../../services/newsflash.service';
@Component({
	selector: 'app-newsflash-attatch-files-popup',
	templateUrl: './newsflash-attatch-files-popup.component.html',
	styleUrls: ['./newsflash-attatch-files-popup.component.scss'],
})
export class NewsflashAttatchFilesPopupComponent {
	idNewflash: number;
	filesNL;
	filesFR;
	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public dialogRef: MatDialogRef<NewsflashAttatchFilesPopupComponent>,
		public newsflashService: NewsflashService,
		public dialog: MatDialog,
		private bottomSheet: MatBottomSheet,
		private snackBarService: SnackBarService,
	) {
		this.idNewflash = this.dialogData.idNewflash;
		[this.filesNL, this.filesFR] = this.getSortedFiles(this.dialogData.files);
	}
	
	uploadDocument(language: string) {
		this.bottomSheet
			.open(BottomSheetComponent, { data: { type: 'file' } })
			.afterDismissed()
			.pipe(
				mergeMap((file) => {
					// Filter out empty responses
					if (file) {
						return of(file);
					}
					return EMPTY;
				}),
				mergeMap((file) => {
					// Filter out wrong document types
					if (this.newsflashService.isFileTypeAllowed(file.type)) {
						return of(file);
					}
					this.snackBarService.open('ç.feature.document.fileTypeNotAllowed', 'error');
					return EMPTY;
				}),
				mergeMap((data) => this.newsflashService.uploadFile(this.idNewflash, data, language))
			)
			.subscribe(
				() => {
					this.newsflashService.getFile(this.idNewflash).subscribe((files) => {
						[this.filesNL, this.filesFR] = this.getSortedFiles(files)
					});
				},
				(error: HttpErrorResponse) => {
					if (error?.error?.file?.length > 0) {
						this.snackBarService.open(error?.error?.file[0], 'error');
					} else {
						this.snackBarService.open('ç.userFeedback.service.error', 'error');
					}
				}
			);
	}

	deleteDocument(idDocument) {
		this.newsflashService.deleteDocument(this.idNewflash, idDocument).subscribe((result) => {
			this.newsflashService.getFile(this.idNewflash).subscribe((files) => {
				[this.filesNL, this.filesFR] = this.getSortedFiles(files)
			});
		});
	}

	getSortedFiles(files) {
		const filesNL = files
			.filter((fil) => fil.lang === 'nl')
			.sort((filA, filB) => filA.id - filB.id)
			.map((fil) => {
				return {
					key: fil.name,
					id: fil.id,
				};
			});
		const filesFR = files
			.filter((fil) => fil.lang === 'fr')
			.sort((filA, filB) => filA.id - filB.id)
			.map((fil) => {
				return {
					key: fil.name,
					id: fil.id,
				};
			});
		return [filesNL, filesFR];
	}
}

import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {LocaleService} from '../../../core/services/locale.service';

@Pipe({
  name: 'environmentCurrency',
})
export class EnvironmentCurrencyPipe extends CurrencyPipe implements PipeTransform {
  constructor(private localeSerivce: LocaleService) {
    super(localeSerivce.locale);
  }

  transform(value: number): any {
    let args: Array<string>;
    switch (this.localeSerivce.region) {
      case 'CH':
        args = ['CHF', 'symbol', '1.2-2', 'de-CH'];
        break;
      default:
        args = ['EUR', 'symbol', '1.2-2', 'en-US'];
    }

    return super.transform(value, ...args);
  }
}

import {Injectable, ErrorHandler, Inject} from '@angular/core';
import {
  ApplicationInsights,
  Environment,
  ENVIRONMENT,
  APP_INSIGHTS_CONFIG
} from '@oper-brokerage-v1/shared/configuration';
import {HttpErrorResponse} from '@angular/common/http';
import {captureException, init} from '@sentry/angular';

@Injectable({providedIn: 'root'})
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    @Inject(APP_INSIGHTS_CONFIG) private applicationInsights: ApplicationInsights
  ) {
    if (this.env.production) {
      console.warn('Initializing Sentry error proxy forwarding!');
      init({
        environment: this.applicationInsights.sentryEnvironmentName ?? this.applicationInsights.name,
        dsn: this.applicationInsights.sentryDNS,
        // integrations: [new CaptureConsole({levels: ['error'],})], TODO https://github.com/getsentry/sentry-docs/issues/1015
        release: this.applicationInsights.version,
        beforeSend(event, hint) {
          const error = hint?.originalException ?? null;
          // Http errors will be handled in the SentryErrorHandler. Else we always have 2 exceptions.
          if (error instanceof HttpErrorResponse) {
            return null;
          }
          if (error && error['message'] && error['message'].match(/Maximum call stack size exceeded/i)) {
            return null;
          }
          return event;
        },
      });
    }
  }

  handleError(error) {
    if (!this.env.production) {
      console.error(error);
    }

    if (this.env.production && this.applicationInsights.sentryDNS) {
      let parsedError = error.originalError || error;
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      // HTTP errors don't have a stack and not presented well in Sentry.
      // This makes sure the information gets into Sentry
      if (parsedError instanceof HttpErrorResponse) {
        parsedError = this.formatHttpError(parsedError);
      }

      // IF error contains '/Loading chunk [\d]+ failed/' reload the page
      // This is necessary because a deployment has happened in the meantime,
      // therefore the already opened application can't load resources which is not in the S3 bucket anymore
      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
      } else {
        captureException(parsedError);
      }

      // Uncomment to show error report popup every time an error fires
      // const eventId = Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog({ eventId });
    }
  }

  private formatHttpError(error: HttpErrorResponse): HttpError | null {
    const status = error?.status;
    const url = error?.url;
    const message = JSON.stringify(error?.error || '');
    if (status === 401 || status === 403) {
      return null;
    }
    const newError = new HttpError(status, url, message);
    return newError;
  }
}

class HttpError extends Error {
  name: string;
  message: string;

  constructor(status: number, url: string, message: string) {
    super();
    this.name = `${status} error from ${url}`;
    this.message = message;
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewsflashService } from '../../services/newsflash.service';
import { DocumentNewsflashPopupComponent } from './document-newsflash-popup/document-newsflash-popup.component';
@Component({
	selector: 'app-newsflash-popup',
	templateUrl: './newsflash-popup.component.html',
	styleUrls: ['./newsflash-popup.component.scss'],
})
export class NewsflashPopupComponent {
	title: string;
	bodyParagrafs: string;
	file;
	documentMimeType;
	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		public dialogRef: MatDialogRef<NewsflashPopupComponent>,
		public newsflashService: NewsflashService,
		public dialog: MatDialog
	) {
		this.title = this.dialogData.language === 'fr-BE' ? this.dialogData.titleFr : this.dialogData.titleNl;
		this.bodyParagrafs = this.dialogData.language === 'fr-BE' ? this.dialogData.bodyFr.split('\n') : this.dialogData.bodyNl.split('\n');
		if (!this.dialogData.confirmationFlag) {
			this.confirmNew();
		}
	}

	confirmNew() {
		this.newsflashService.confirmNew(this.dialogData.id).subscribe();
	}

	confirmNewByClick(){
		this.confirmNew();
		this.dialogRef.close();
	}

	viewDocument() {
		this.dialog.open(DocumentNewsflashPopupComponent, {
			minWidth: '96vw',
			minHeight: '96vh',
			data: { files: this.dialogData.files },
		});
	}
}

import {ThirdParty} from '../../third-party/models/third-party.model';
import {LoanRequest} from '../../loan-request/models/loan-request.model';
import {Resource} from '../../../shared/models/resource/resource.model';
import {Client} from '../../client/models/client.model';

export class InsuranceProvider extends ThirdParty {
  fsmaReference: string;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      super(data);
      this.fsmaReference = data.fsmaReference || null;
    }
  }

  static createInsuranceProvider(data) {
    if (data && data.id) {
      return new InsuranceProvider(data);
    } else {
      return null;
    }
  }
}

export class Insurance {
  id: number;
  insuranceType: Resource;
  loanRequest: LoanRequest;
  provider: InsuranceProvider;
  inventoryProvider: InsuranceProvider;
  premium: number;
  periodicity: Resource;
  policyNumber: string;
  insuredAmount: number;
  coveredAmount: number;
  duration: number;
  toBeFinanced: boolean;
  clients: Array<Client>;
  legalEntities: Array<any>;
  apartmentComplexPolicy: Boolean;
  mandatory: boolean;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.insuranceType = new Resource(data.insuranceType);
      this.loanRequest = new LoanRequest(data.loanRequest);
      this.provider = InsuranceProvider.createInsuranceProvider(data.provider);
      this.premium = data.premium || 0;
      this.periodicity = new Resource(data.periodicity);
      this.policyNumber = data.policyNumber || '';
      this.insuredAmount = data.insuredAmount || 0;
      this.coveredAmount = data.coveredAmount || 0;
      this.duration = data.duration || 0;
      this.toBeFinanced = data.toBeFinanced !== undefined ? data.toBeFinanced : false;
      this.mandatory = data.mandatory !== undefined ? data.mandatory : false;
      this.apartmentComplexPolicy = data.apartmentComplexPolicy || false;
      this.clients = [];
      this.legalEntities = [];
      this.inventoryProvider = InsuranceProvider.createInsuranceProvider(data.inventoryProvider);
      if (!(data.clients === undefined)) {
        data.clients.forEach(client => {
          this.clients.push(new Client(client));
        });
      }
      if (!(data.legalEntities === undefined)) {
        data.legalEntities.forEach(le => {
          this.legalEntities.push(le);
        });
      }
    }
  }
}

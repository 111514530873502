import {ValidatorFn, AsyncValidatorFn} from '@angular/forms';
import {InputSelect, InputField, InputSubmit, InputChipItems, Spacer, DateInput} from './input-types.model';

export class FormConfiguration {
  formControl: { questions: Array<InputSelect | InputField | InputSubmit | InputChipItems | Spacer | DateInput> };
  formGroup: { validators: Array<ValidatorFn>; asyncValidators: Array<AsyncValidatorFn> };

  constructor() {
    this.formControl = {questions: []};
    this.formGroup = {validators: [], asyncValidators: []};
  }
}

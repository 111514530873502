import {NgModule, APP_INITIALIZER} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {NoopAuthGuard} from './guards/noop-auth.guard';

import {HasFeatureFlagDirective} from './directives/has-feature-flag.directive';
import {FeatureFlagService} from './services/feature-flag.service';
import {TranslateModule} from '@ngx-translate/core';

import {ConnectionLostInterceptor} from './services/connection-lost.interceptor';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {CaseStyleInterceptor} from './interceptors/case-style.interceptor';
import {RefreshTokenInterceptor} from './interceptors/refresh-token.interceptor';
import {ScrollTopDirective} from './directives/scroll-top.directive';

declare const COMMIT_HASH: string;

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, `./assets/${COMMIT_HASH}/i18n/`, '.json');
}

export function FeatureLoader(featureFlagService: FeatureFlagService) {
  return function () {
    return featureFlagService.initFeatureFlags();
  };
}

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule],
  providers: [
    NoopAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CaseStyleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {provide: APP_INITIALIZER, multi: true, useFactory: FeatureLoader, deps: [FeatureFlagService]},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConnectionLostInterceptor,
      multi: true,
    },
  ],
  declarations: [HasFeatureFlagDirective, ScrollTopDirective],
  exports: [TranslateModule, HasFeatureFlagDirective, ScrollTopDirective],
})
export class CoreModule {
}

import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ValidatorFn, AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {ResourceService} from '../resource/resource.service';
import {DateFormatPipe} from '../../pipe/date-format/date-format.pipe';
import { Card } from '../../models/card/card.model';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor(
    private readonly translate: TranslateService,
    private readonly resourceService: ResourceService,
    private readonly datePipe: DateFormatPipe
  ) {
  }

  positiveValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value < 0 ? {positive: true} : null;
    };
  }

  strictlyPositiveValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value <= 0 ? {positive: true} : null;
    };
  }

  negativeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value >= 0 ? {negative: true} : null;
    };
  }

  futureValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputDate = new Date(control.value);
      const now = new Date();
      const difference = now.getTime() - inputDate.getTime();
      return difference > 0 ? {future: true} : null;
    };
  }

  pastValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputDate = new Date(control.value);
      const now = new Date();
      const difference = now.getTime() - inputDate.getTime();
      return difference < 0 ? {past: true} : null;
    };
  }

  maxPercentageValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value > 100 ? {maxPercentage: true} : null;
    };
  }

  maxValueValidator(maxValue): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value > maxValue ? {maxValue: true} : null;
    };
  }

  minAgeValidator(minAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputDate = new Date(control.value);
      const now = new Date();
      const difference = now.getTime() - inputDate.getTime();
      return difference / 31536000000 < minAge ? {minAge: {requiredAge: minAge}} : null;
    };
  }

  maxAgeValidator(maxAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.calculateDiff(new Date(control.value), new Date()) >= maxAge ? {maxAge: {requiredAge: maxAge}} : null;
    };
  }

  // This method return -1 form maxAge becouse validate error is not match with label error
  maxAgeBorrowerValidator(maxAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.calculateDiff(new Date(control.value), new Date()) >= maxAge ? {maxAge: {requiredAge: maxAge - 1}} : null;
    };
  }

  maxSimbolValidator(maxSimbol: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value.length > maxSimbol ? {maxSimbol: {maxSimbol: maxSimbol}} : null
    };
  }

  /**
   * Calculation how much full years|months between dates.
   * Day of months are valuable, because if we even 18 years between 1984 - 2002,
   * but we do not have 18 years between March 03, 1984  - March 01, 2002
   * @param begin - start of period
   * @param end - end of period
   */
  calculateDiff(begin: Date, end: Date, unit: 'month' | 'year' = 'year') {
    const bMonth = begin.getMonth();
    let eMonth = end.getMonth();

    const bYear = begin.getFullYear();
    let eYear = end.getFullYear();

    const bDay = begin.getDate();
    let eDay = end.getDate();

    const bDays = this.calculateNumOfDays(bMonth, bYear);
    const eDays = this.calculateNumOfDays(eMonth, eYear);

    if (eDay - bDay < 0) {
      eMonth = eMonth - 1;
      eDay = eDay + bDays;
    }

    const daysDiff = eDay - bDay;

    if (eMonth - bMonth < 0) {
      eYear = eYear - 1;
      eMonth = eMonth + 12;
    }

    let monthDiff = eMonth - bMonth;

    let yearDiff = eYear - bYear;

    if (daysDiff === eDays) {
      monthDiff = monthDiff + 1;

      if (monthDiff === 12) {
        yearDiff = yearDiff + 1;
        monthDiff = 0;
      }
    }

    switch (unit) {
      case 'month':
        return 12 * yearDiff + monthDiff;
      default:
        return yearDiff;
    }
  }

  calculateNumOfDays(month, year): number {
    if ((month === 1 && year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      return 29;
    }

    if (month === 1 && (year % 4 !== 0 || year % 100 === 0)) {
      return 28;
    }

    if (month === 0 || month === 2 || month === 4 || month === 6 || month === 7 || month === 9 || month === 11) {
      return 31;
    }
    return 30;
  }

  modulo97(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputDate = +(control.value || 0);
      if (isNaN(inputDate) || inputDate === 0) {
        return null;
      }
      const firstPart = Math.floor(inputDate / 100);
      const lastPart = inputDate % 100;
      return firstPart % 97 === lastPart ? null : {modulo97: true};
    };
  }

  similarFieldsFormGroupValidator(fields: Array<string>): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const duplicates: Array<string> = [];
      const len = fields.length;
      for (let i = 0; i < len - 1; i++) {
        for (let j = 1; j < len; j++) {
          if (control.get(fields[i]) && control.get(fields[j]) && control.get(fields[i]).value === control.get(fields[j]).value) {
            duplicates.push(`${fields[i]} & ${fields[j]}`);
          }
        }
      }
      if (duplicates.length > 0) {
        return {similarFields: {similarFields: duplicates}};
      } else {
        return null;
      }
    };
  }

  creditCardBalancetakeoverValidator() {
    // If takeover is yes, then the balance must be filled in.
    return (control: FormGroup): ValidationErrors | null => {
      const takeover = (control.value && control.value['takeover.id']) || null;
      const toTakeOver = this.resourceService.getResourceInstance('credit-takeover', 'definition', 'yes');
      if (toTakeOver && takeover === toTakeOver.id) {
        return !control.value.balance ? {creditCardTakeOver: true} : null;
      }
      return null;
    };
  }

  chipRequiredValidator(questionName: string) {
    // See that the  chips has at least one member
    return (control: FormGroup): ValidationErrors | null => {
      const chipList = (control.value && control.value[questionName]) || null;
      if (!(chipList && chipList.length)) {
        return {required: true};
      }
      return null;
    };
  }

  irregularityStartDateValidator() {
    return (control: FormGroup): ValidationErrors | null => {
      const regularity = (control.value && control.value['regularity.id']) || null;
      const startDate = (control.value && control.value['irregularityStartDate']) || null;
      const regularCredit = this.resourceService.getResourceInstance('regularity-type', 'definition', 'regular');
      if (regularity) {
        if (regularity === regularCredit.id) {
          return startDate ? {irregularityStartDateMustBeEmpty: true} : null;
        } else {
          return startDate ? null : {irregularityStartDateMustBeFilled: true};
        }
      }
      return null;
    };
  }

  monthsBeforeOtherDate(otherDate, months): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!otherDate) {
        return null;
      }
      const deedDate = new Date(control.value);
      const otherDateObject = new Date(otherDate);
      const compareDate = new Date(otherDateObject.setMonth(otherDateObject.getMonth() + months));
      if (deedDate >= compareDate) {
        const printDate = this.datePipe.transform(compareDate);
        return {monthsBeforeOtherDate: {otherDate: printDate}};
      }
      return null;
    };
  }

  couldNotBeInFuture(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const submitionDate = new Date(control.value);
      const compareDate = new Date();
      if (submitionDate > compareDate) {
        return {couldNotBeInFuture: true};
      }
      return null;
    };
  }

  mustBeAfterOtherDate(otherDate): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!otherDate) {
        return null;
      }
      const submitionDate = new Date(control.value);
      const compareDate = new Date(otherDate);
      if (submitionDate < compareDate) {
        return {mustBeAfterOtherDate: true};
      }
      return null;
    };
  }

  liabilityInsuranceToBeFinancedMustBeOneShot(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const toBeFinanced = control.value['toBeFinanced'];
      const periodicityId = control.value['periodicity.id'];
      const oneShotId = this.resourceService.getResourceInstance('periodicity', 'definition', 'oneShot').id;

      if (toBeFinanced === undefined || periodicityId === undefined) {
        return null;
      }

      if (toBeFinanced === true && periodicityId !== oneShotId) {
        return {liabilityInsuranceToBeFinancedMustBeOneShot: true};
      }
      return null;
    };
  }

  incorrectBirthDayForNationalNmber() {
    const nationalNumberRegexBE = /(\d{2})\.(\d{2})\.(\d{2})-\d{3}\.\d{2}/;
    return (control: FormGroup): ValidationErrors | null => {
      const nationalNumber = control.value['nationalNumber'];
      if (nationalNumberRegexBE.test(nationalNumber)){
        const birthDate = control.value['birthDate'].split('-');
        const groups = nationalNumber.match(nationalNumberRegexBE);
        const year = Number(groups[1]);
        const month = Number(groups[2]);
        const day = Number(groups[3]);
      //   if (birthDate[0] && year != Number(birthDate[0].slice(2)) ||
      //       (
      //         month !== 0 && month <= 12 &&
      //         (
      //           (!birthDate[1] || (birthDate[1] && Number(birthDate[1]) !== month)) ||
      //           (
      //             day !== 0 && day <= 31 &&
      //             (!birthDate[2] || (birthDate[2] && Number(birthDate[2]) !== day))
      //           )
      //         )
      //       )
      //   ) {
      //     return {incorrectBirthDayForNationalNmber: true}
      //   }
      }
      return null;
    };
  }

  incorrectSexForNationalNumber() {
    const nationalNumberRegexBE = /\d{2}\.\d{2}\.\d{2}-(\d{3}).\d{2}/;
    return (control: FormGroup): ValidationErrors | null => {
      const nationalNumber = control.value['nationalNumber'];
      if (nationalNumberRegexBE.test(nationalNumber)){
        const sex = Number(nationalNumber.match(nationalNumberRegexBE)[1]);
        const formSexId = control.value['sex.id'];
        if (
          sex % 2 === 0 && formSexId !== 2 ||
          sex % 2 === 1 && formSexId !== 1
        ){
          return {incorrectSexForNationalNumber: true}
        }
      }
      return null;
    };
  }

  formatValidationErrors(errors, questionLabel = '') {
    return Object.keys(errors).map((key) => {
      if (key === 'required') {
        return `${this.translate.instant(questionLabel)} ${this.translate.instant(this.formatValidationError(key, errors[key]))}`;
      } else {
        return this.translate.instant(this.formatValidationError(key, errors[key]));
      }
    });
  }

  maxAmoutForDifferentAdditionalFinancingNeedTypes(){
    return (control: FormGroup): ValidationErrors | null => {
      const idTaxNewConstruction = this.resourceService.getResourceInstance('additional-financing-need-type', 'definition', 'TAXNewConstruction').id;
      const idWorkingCapital = this.resourceService.getResourceInstance('additional-financing-need-type', 'definition', 'workingCapital').id;
      const additionalFinancingNeedTypeId = control.value['additionalFinancingNeedType.id'];
      const amount = control.value['amount'];
      const maxAmounts: { [key: string]: number } = {
        [idTaxNewConstruction]: 150000,
        [idWorkingCapital]: 100000,
      };
      const defaultMaxAmount = 50000;
      const maxAmount = maxAmounts[additionalFinancingNeedTypeId] || defaultMaxAmount;
      return amount && amount > maxAmount ? { maxAmount: { max: maxAmount } } : null;
    }
  }

  repaymentBankAccountValidate(){
    const repaymentBankAccountRE = /BE\d{14}\b/;
    return (control: AbstractControl): ValidationErrors | null => {
      const repaymentBankAccount = control.value.toUpperCase().split(' ').join('').toUpperCase();
      if (repaymentBankAccount.length == 0) return null;
      if (repaymentBankAccountRE.test(repaymentBankAccount) && repaymentBankAccount.length === 16){
        const divider = 97;
        const pertBankAccountForChecking = Number(repaymentBankAccount.slice(-2));
        const mainPartBankAccount = Number(repaymentBankAccount.slice(4,-2));
        if (
          pertBankAccountForChecking !== 0 && mainPartBankAccount % divider === pertBankAccountForChecking ||
          mainPartBankAccount % divider === 0 && pertBankAccountForChecking === 97
          ){
            return null;
          }
      }
      return {incorrectBankAccount: true}
    };
  }

  formatValidationError(key, options = null) {
    switch (key) {
      case 'required':
        return `ç.question.validator.required`;
      case 'email':
        return `ç.question.validator.email`;
      case 'minlength':
        return this.translate.instant('ç.question.validator.minLength', {requiredLength: options.requiredLength});
      case 'maxlength':
        return this.translate.instant('ç.question.validator.maxLength', {requiredLength: options.requiredLength});
      case 'minAge':
        return this.translate.instant('ç.question.validator.minAge', {requiredAge: options.requiredAge});
      case 'maxAge':
        return this.translate.instant('ç.question.validator.maxAge', {requiredAge: options.requiredAge});
      case 'future':
        return `ç.question.validator.future`;
      case 'past':
        return `ç.question.validator.past`;
      case 'negative':
        return `ç.question.validator.negative`;
      case 'positive':
        return `ç.question.validator.positive`;
      case 'min':
        return this.translate.instant('ç.question.validator.min', {min: options.min});
      case 'max':
        return this.translate.instant('ç.question.validator.max', {max: options.max});
      case 'similarFields':
        return this.translate.instant('ç.question.validator.similarFields', {similarFields: options.similarFields});
      case 'maxPercentage':
        return `ç.question.validator.maxPercentage`;
      case 'minPercentage':
        return `ç.question.validator.minPercentage`;
      case 'venalValue':
        return `ç.question.validator.venalValuePurchasePrice`;
      case 'venalBeforeAfter':
        return `ç.question.validator.venalBeforeAfter`;
      case 'requiredEstimated':
        return `ç.question.validator.requiredEstimated`;
      case 'maxRenovation':
        return this.translate.instant('ç.question.validator.maxRenovation', {max: options.max});
      case 'maxAmount':
        return this.translate.instant('ç.question.validator.maxAmount', {max: options.max});
      case 'creditCardTakeOver':
        return `ç.question.validator.creditCardTakeOverRequiresBalance`;
      case `moreThanTwoMonthsAgo`:
        return `ç.question.validator.moreThanTwoMonthsAgo`;
      case `moreThanThreeYearsAgo`:
        return `ç.question.validator.moreThanThreeYearsAgo`;
      case `expiredIdCard`:
        return `ç.question.validator.expiredIdCard`;
      case `pattern`:
        if (options.requiredPattern === '^([^0-9]*)$') {
          return `ç.question.validator.noNumbers`;
        }
        return `ç.question.validator.pattern`;
      case 'registrationRightsDetailsRequired':
        return `ç.question.validator.registrationRightsDetailsRequired`;
      case 'irregularityStartDateMustBeEmpty':
        return `ç.question.validator.irregularityStartDateMustBeEmpty`;
      case 'irregularityStartDateMustBeFilled':
        return `ç.question.validator.irregularityStartDateMustBeFilled`;
      case 'nationalNumberInvalid':
        return `ç.question.validator.nationalNumber`;
      case 'modulo97':
        return `ç.question.validator.modulo97`;
      case 'atLeastOneRequestRequired':
        return `ç.question.validator.atLeastOneRequestRequired`;
      case `monthsBeforeOtherDate`:
        return this.translate.instant('ç.question.validator.monthsBeforeOtherDate', {otherDate: options.otherDate});
      case `mustBeAfterOtherDate`:
        return 'ç.question.validator.mustBeAfterOtherDate';
      case `couldNotBeInFuture`:
        return 'ç.question.validator.couldNotBeInFuture';
      case `liabilityInsuranceToBeFinancedMustBeOneShot`:
        return `ç.question.validator.liabilityInsuranceToBeFinancedMustBeOneShot`;
      case `otherEstimatorNameMustBeFilled`:
        return `ç.question.validator.otherEstimatorNameMustBeFilled`;
      case `otherEstimatorNameMustBeEmpty`:
        return `ç.question.validator.otherEstimatorNameMustBeEmpty`;
      case `incorrectBirthDayForNationalNmber`:
        return `ç.feature.validator.incorrectDateOfBirth`;
      case`incorrectSexForNationalNumber`:
        return `ç.feature.validator.incorrectSexForNationalNumber`;
      case`incorrectBankAccount`:
          return `ç.feature.validator.incorrectBankAccount`;
      case`notSameVale`:
          return `ç.feature.validator.notSameVale`;
      case`maxOwnTypes`:
          return `ç.feature.validator.maxOwnTypes`;
      default:
        return `ç.question.validator.default`;
    }
  }

}

<div class="list-header">
	<div class="action">
		<i class="uil uil-multiply" (click)="dialogRef.close()"></i>
	</div>
</div>

<div class="documetnConteiner">
	<div *ngFor="let dFile of dataFiles">
		<div mat-dialog-content class="mat-dialog-content" *ngIf="dFile.documentMimeType === 'pdf'">
			<object [data]="dFile.file" type="application/pdf">
				<p>
					{{ 'ç.misc.pdf.noAdobeReader' | translate }}
				</p>
				<a href="{{ dFile.link }}">{{ 'ç.misc.pdf.downloadPdf' | translate }}</a>
				<br />
				<a href="http://get.adobe.com/reader/" target="_blank">{{ 'ç.misc.pdf.getAdobeReader' | translate }}</a>
				<embed [src]="dFile.file" type="application/pdf" />
			</object>
		</div>

		<div mat-dialog-content class="mat-dialog-content" *ngIf="dFile.documentMimeType === 'image'">
			<img [src]="dFile.file" />
		</div>
	</div>
</div>

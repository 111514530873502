import {Component, OnInit, Inject} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {ApplicationInsights, APP_INSIGHTS_CONFIG} from '@oper-brokerage-v1/shared/configuration';

@Component({
  selector: 'app-root',
  template: `
		<router-outlet></router-outlet>
	`,
})
export class AppComponent implements OnInit {
  public constructor(private titleService: Title, @Inject(APP_INSIGHTS_CONFIG) private applicationInsights: ApplicationInsights) {
  }

  ngOnInit() {
    this.setTitle(this.applicationInsights.appTitle);
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}

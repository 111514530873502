export class Permission {
  appLabel: string;
  codename: string;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.appLabel = data.appLabel || '';
      this.codename = data.codename || '';
    }
  }
}

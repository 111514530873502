import {Injectable} from '@angular/core';
import {LocaleService} from '../../../core/services/locale.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentCurrencyService {
  constructor(private localeService: LocaleService) {
  }

  getSymbol(): string {
    switch (this.localeService.region) {
      case 'CH':
        return 'CHF';
      default:
        return '€';
    }
  }
}

<mat-nav-list *ngIf="data.type === 'file'">
  <a mat-list-item>
    <input type="file" (change)="selectFile($event)" multiple mat-line/>
  </a>
  <!--
  <a mat-list-item>
    <input type="file" (change)="selectFile($event)" hidden />
    <p mat-line>Paste URL</p>
  </a> -->
</mat-nav-list>

<mat-nav-list
  *ngIf="
		data.type === 'message' &&
		(featureFlagService.hasFeatureFlag('communicationMail') ||
			featureFlagService.hasFeatureFlag('communicationText') ||
			featureFlagService.hasFeatureFlag('communicationWhatsapp'))
	"
>
  <ng-container *ngIf="featureFlagService.hasFeatureFlag('communicationText')">
    <a mat-list-item (click)="onClick(1)">
      <i class="uil uil-comment-alt-lines"
         style="margin-right: 8px;"></i>{{ 'ç.resource.communication-type.text' | translate }}
    </a>
  </ng-container>
  <ng-container *ngIf="featureFlagService.hasFeatureFlag('communicationMail')">
    <a mat-list-item (click)="onClick(2)">
      <i class="uil uil-envelope-alt"
         style="margin-right: 8px;"></i>{{ 'ç.resource.communication-type.mail' | translate }}
    </a>
  </ng-container>
  <ng-container *ngIf="featureFlagService.hasFeatureFlag('communicationWhatsapp')">
    <a mat-list-item (click)="onClick(3)">
      <i class="uil uil-whatsapp"
         style="margin-right: 8px;"></i>{{ 'ç.resource.communication-type.whatsapp' | translate }}
    </a>
  </ng-container>
</mat-nav-list>

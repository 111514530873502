import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Broker } from '../models/broker.model';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import { ResourceService } from '../../../shared/services/resource/resource.service';
import { CaseStyleService } from '../../../shared/services/case-style/case-style.service';
import { InputField, InputSelect } from '../../../shared/models/dynamic-form/input-types.model';
import { Validators } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class BrokerService {
	defaultPageSize = 100;
	defaultPageSizeOptions = [5, 10, 25, 50, 100];

	constructor(private apiService: ApiService, private resourceService: ResourceService, private caseStyleService: CaseStyleService) {}

	public getBrokers(queryParams = {}) {
		const httpParams: HttpParams = this.setHttpParams(queryParams);
		return this.apiService.get(`/api/third-parties/brokers/`, httpParams);
	}

	public exportAsCsv(queryParams = {}) {
		const httpParams: HttpParams = this.setHttpParams(queryParams);
		return this.apiService.download(`/api/third-parties/brokers/csv`, httpParams);
	}

	public getBroker(brokerId: number): Observable<Broker> {
		return this.apiService.get(`/api/third-parties/brokers/${brokerId}/`);
	}

	public setHttpParams(queryParams = {}) {
		let params = new HttpParams();
		if (!queryParams['page']) {
			queryParams['page'] = '1';
		}
		if (!queryParams['page_size']) {
			queryParams['page_size'] = this.defaultPageSize;
		}
		// queryParams = this.caseStyleService.convertKeysToSnakeCase(queryParams);
		for (const key of Object.keys(queryParams)) {
			params = params.set(key, queryParams[key]);
		}
		return params;

		// return new HttpParams()
		// 	.set('loan_request', params.loanRequestId.toString())
		// 	.set('page', params.pagination.pageNumber.toString())
		// 	.set('page_size', params.pagination.pageSize.toString())
		// 	.set('search', params.filter);
	}

	public updateBroker(brokerId, body): Observable<Broker> {
		return this.apiService.patch(`/api/third-parties/brokers/${brokerId}/`, this.getCorrectBody(body));
	}

	public createBroker(body): Observable<Broker> {
		return this.apiService.post(`/api/third-parties/brokers/`, this.getCorrectBody(body));
	}

	public updateCommissionClass(brokerId, commissionClass): Observable<any> {
		return this.apiService.post('/api/commissions/configurations/', {
			commissionClass: {
				id: commissionClass,
			},
			broker: {
				id: brokerId,
			},
		});
	}

	compareBrokersByName(a: Broker, b: Broker): number {
		// Use toUpperCase() to ignore character casing
		const nameA = a.name.toUpperCase();
		const nameB = b.name.toUpperCase();

		let comparison = 0;
		if (nameA > nameB) {
			comparison = 1;
		} else if (nameA < nameB) {
			comparison = -1;
		}
		return comparison;
	}

	public createCobrokers(body) {
		return this.apiService.post(`/api/third-parties/co-brokers/`, body);
	}

	public deleteCobrokers(body) {
		return this.apiService.delete(`/api/third-parties/co-brokers/${body.brokerFrom}-${body.brokerTo}/`);
	}

	getCorrectBody(broker) {
		const addresses = broker.addresses.map((address) => {
			return {
				...address,
				country: this.resourceService.resources['country'].find((count) => count.id == address.country.id),
				addressType: this.resourceService.getResourceInstances('contact-type').find((res) => res.definition == 'main'),
			};
		});
		const emails =
			broker.emails[0].value !== ''
				? broker.emails.map((email) => {
						return {
							...email,
							emailType: this.resourceService.getResourceInstances('contact-type').find((res) => res.definition == 'main'),
						};
				  })
				: null;
		const phoneNumbers =
			broker.phoneNumbers[0].value !== ''
				? broker.phoneNumbers.map((phoneNumber) => {
						return {
							...phoneNumber,
							phoneType: this.resourceService.getResourceInstances('contact-type').find((res) => res.definition == 'main'),
						};
				  })
				: null;
		const companyType = this.resourceService.getResourceInstances('company-type').find((res) => res.id === broker.companyType);
		return {
			...broker,
			addresses: addresses,
			phoneNumbers: phoneNumbers,
			emails: emails,
			companyType: companyType === undefined ? null : companyType,
			fsmaStatus: this.resourceService.getResourceInstances('fsma-status').find((res) => res.id === broker.fsmaStatus),
		};
	}

	getBrokerQuestion(broker = new Broker(), brokers, accountManagers) {
		const companyType = [{
			id: null,
			key: null
		}]
		companyType.push(...this.resourceService.getResourceInstances('company-type'))
		const questions = [
			new InputField({
				key: 'name',
				label: 'ç.feature.thirdParty.broker.form.brokerName',
				value: broker.name || '',
				type: '',
				class: 'span6',
				required: true,
			}),
			new InputField({
				key: 'vatNumber',
				label: 'ç.feature.thirdParty.notary.form.vatNumber',
				value: broker.vatNumber || '',
				type: '',
				class: 'span6',
				required: false,
			}),
			new InputField({
				key: 'bankAccount',
				label: 'ç.feature.thirdParty.broker.form.bankAccount',
				value: broker.bankAccount || '',
				type: 'text',
				class: 'span4',
				required: false,
			}),
			new InputField({
				key: 'externalId',
				label: 'ç.feature.thirdParty.broker.form.externalId',
				value: broker.externalId || '',
				type: 'text',
				class: 'span4',
				required: false,
			}),
			new InputSelect({
				key: 'companyType',
				options: companyType,
				label: 'ç.feature.thirdParty.broker.form.companyType',
				value: broker.companyType?.id || null,
				class: 'span2',
				required: false,
			}),
			new InputSelect({
				key: 'isActive',
				label: 'ç.feature.thirdParty.broker.form.isActive',
				options: [
					{
						key: 'ç.misc.action.yes',
						id: true,
					},
					{
						key: 'ç.misc.action.no',
						id: false,
					},
				],
				value: broker.isActive === false ? false : true,
				class: 'span2',
				searchable: true,
				required: false,
			}),
			new InputField({
				key: 'addresses[0].street',
				label: 'ç.question.street.label',
				value: (broker.addresses && broker.addresses.length && broker.addresses[0].street) || '',
				type: '',
				class: 'span8',
				validators: [Validators.required],
			}),
			new InputField({
				key: 'addresses[0].houseNumber',
				label: 'ç.question.houseNumber.label',
				value: (broker.addresses && broker.addresses.length && broker.addresses[0].houseNumber) || '',
				type: '',
				validators: [Validators.required, Validators.maxLength(8)],
				class: 'span2',
			}),
			new InputField({
				key: 'addresses[0].box',
				label: 'ç.question.box.label',
				value: (broker.addresses && broker.addresses.length && broker.addresses[0].box) || '',
				type: '',
				validators: [Validators.maxLength(8)],
				required: false,
				class: 'span2',
			}),
			new InputField({
				key: 'addresses[0].zipCode',
				label: 'ç.question.zipCode.label',
				value: (broker.addresses && broker.addresses.length && broker.addresses[0].zipCode) || '',
				type: '',
				validators: [Validators.required, Validators.maxLength(8)],
				class: 'span2',
			}),
			new InputField({
				key: 'addresses[0].city',
				label: 'ç.question.city.label',
				value: (broker.addresses && broker.addresses.length && broker.addresses[0].city) || '',
				type: '',
				validators: [Validators.required, Validators.pattern('^([^0-9]*)$')],
				class: 'span5',
			}),
			new InputSelect({
				key: 'addresses[0].country.id',
				label: 'ç.question.country.label',
				options: this.resourceService.resources['country'],
				value:
					(broker.addresses && broker.addresses.length && broker.addresses[0].country && broker.addresses[0].country.id) ||
					this.resourceService.getDefault('country')?.id,
				class: 'span5',
				searchable: true,
			}),
			new InputField({
				key: 'phoneNumbers[0].value',
				label: 'ç.question.phoneNumber.label',
				value: (broker.phoneNumbers && broker.phoneNumbers.length && broker.phoneNumbers[0].value) || '',
				type: 'tel',
				required: false,
				class: 'span4',
			}),
			new InputField({
				key: 'emails[0].value',
				label: 'ç.question.email.label',
				value: (broker.emails && broker.emails.length && broker.emails[0].value) || '',
				type: 'email',
				required: false,
				class: 'span4',
			}),
			new InputSelect({
				key: 'parent',
				label: 'ç.feature.thirdParty.broker.form.parent',
				options: brokers,
				value: broker.parent || null,
				class: 'span4',
				searchable: true,
				required: false,
			}),
			new InputSelect({
				key: 'fsmaStatus',
				label: 'ç.feature.thirdParty.broker.form.fsmaStatus',
				options: this.resourceService.resources['fsma-status'],
				value: broker.fsmaStatus?.id || null,
				class: 'span3',
				searchable: true,
				required: false,
			}),
			new InputSelect({
				key: 'accountManager',
				label: 'ç.feature.thirdParty.broker.accountManager',
				options: accountManagers,
				value: broker.accountManager?.id || null,
				class: 'span4',
				searchable: true,
				required: false,
			}),
			new InputSelect({
				key: 'brokerType',
				label: 'ç.feature.thirdParty.broker.form.brokerType',
				options: this.resourceService.resources['broker-type'],
				value: broker.brokerType || null,
				class: 'span3',
				searchable: true,
				required: true,
			}),
			new InputSelect({
				key: 'isCoBroker',
				label: 'ç.feature.thirdParty.broker.form.isCoBroker',
				options: [
					{
						key: 'ç.misc.action.yes',
						id: true,
					},
					{
						key: 'ç.misc.action.no',
						id: false,
					},
				],
				value: broker.isCoBroker === true ? true : false,
				class: 'span2',
				searchable: true,
				required: false,
			}),
		];
		return questions;
	}

	getCobrokerQuestion(brokers) {
		return [
			new InputSelect({
				key: 'coBroker',
				label: 'ç.feature.thirdParty.broker.coBroker.singular',
				options: brokers,
				value: null,
				class: 'span12',
				searchable: true,
				validators: [Validators.required],
			}),
		];
	}
}

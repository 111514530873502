import {Injectable, Inject, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {CustomerInsights, CUSTOMER_INSIGHTS_CONFIG} from '@oper-brokerage-v1/shared/configuration';

import {AuthService} from '../services/auth.service';

declare const COMMIT_HASH: string;

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private inj: Injector, @Inject(CUSTOMER_INSIGHTS_CONFIG) private readonly customerInsights: CustomerInsights) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // In the first request we still don't have a config
    if (request.url === `/assets/${COMMIT_HASH}/config/customer.json` || !request.url.startsWith(this.customerInsights.serverUrl)) {
      return next.handle(request);
    }
    const token = this.inj.get(AuthService).getAccessToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Token ${token}`,
        },
      });
    }

    return next.handle(request);
  }
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadService {
  public loading$: Subject<boolean>;
  private _disableLoading = false;

  constructor() {
    this.loading$ = new Subject<boolean>();
  }

  public startLoading(): void {
    if (!this.disableLoading) {
      // TODO disableLoading() result is always falsy
      this.loading$.next(true);
    }
  }

  public stopLoading(): void {
    this.loading$.next(false);
  }

  public disableLoading(): void {
    this._disableLoading = true;
  }

  public enableLoading(): void {
    this._disableLoading = false;
  }
}

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthService} from '../services/auth.service';
import {JwtService} from '../services/jwt.service';
import {SessionStorageService} from '../services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly jwtService: JwtService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly router: Router
  ) {
  }

  currentRole = '';
  expectedRoles = [];

  // TODO: Detach from roles and use permissions

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Get all roles, which have been granted access to the route, from the routing module
    this.expectedRoles = next.data.expectedRoles;
    // Get the role of the user
    const accessToken = this.authService.getAccessToken();
    if (accessToken) {
      this.currentRole = this.jwtService.unpackToken(accessToken).role.definition;
    }

    // check if the user role is one of the expected roles for the route
    if (this.expectedRoles.find(expectedRole => expectedRole === this.currentRole) && this.authService.isAuthenticated()) {
      return true;
      // or if the url contains a token where the payload contains a loan request object
    } else if (
      next.queryParams.token &&
      this.jwtService.unpackToken(next.queryParams.token)
        .loanRequest /*&&
			this.expectedRoles.find(expectedRole => expectedRole === this.currentRole)*/
    ) {
      this.sessionStorageService.set(
        'loanRequest',
        JSON.stringify({id: this.jwtService.unpackToken(next.queryParams.token).loanRequest})
      );
      this.authService.setTokens({
        access: '',
        refresh: next.queryParams.token,
      });
      return true;
      // if not, block the user from the application and send him to the login screen
    } else {
      this.authService.targetRoute = {url: state.url, queryParams: next.queryParams};
      this.router.navigate(['auth/login']);
      return false;
    }
  }
}

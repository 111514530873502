<div class="containerDialog">
  <div class="header">
    <h1 mat-dialog-title class="mat-dialog-title">{{ 'ç.dialog.ownFundsOrigin.title' | translate }}</h1>
    <span class="spacer"></span>
    <button
      *ngIf="dialogData.changeConfiguration"
      class="change-configuration"
      mat-stroked-button
      color="primary"
      (click)="dialogRef.close(); dialogData.changeConfiguration.callBack(form)"
      [ngClass]="{'readonly-block': permissionService.hasPermission('main.auditorExcludeEditing')}"
      [tabindex]="permissionService.hasPermission('main.auditorExcludeEditing') ? -1 : null"
    >
      {{ dialogData.changeConfiguration.title | translate }} <i [ngClass]="['uil', 'uil-pen']"></i>
    </button>
    <i class="uil uil-multiply" (click)="dialogRef.close()"></i>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content class="containerContent">
    <div class="containerTotal">
      {{'ç.feature.offer.total' | translate}}: {{currentSumOwnFunds | environmentCurrency}} / {{ ownFundsTotal | environmentCurrency}}
    </div>
    <div class="containerTotal left">
      {{ 'ç.dialog.allocateFinances.left' | translate }}: {{leftOwnFunds | environmentCurrency}}
    </div>
    <div class="question">
      <p>{{ dialogData.description | translate }}</p>
      <div class="containerErrorList" *ngFor="let formConfigurationError of formConfigurationErrors">
        <span>&#8226; {{ formConfigurationError }}</span>
      </div>
      <div class="containerWarningList" *ngFor="let warning of formConfigurationWarnings">
        <span>&#8226; {{ warning }}</span>
      </div>
    </div>
    <app-dynamic-form
      [formConfiguration]="formConfiguration"
      (valueChange)="formValueChanged($event)"
      (valueInit)="formValueInit($event)"
      (errors)="getFormConfigurationErrors($event)"
      (warnings)="getFormConfigurationWarnings($event)"
      (buttonClicked)="onButtonClicked()"
      class="app-dynamic-form"
    >
    </app-dynamic-form>
  </div>
  <div mat-dialog-actions class="containerButtons">
    <button mat-button (click)="onCancel()">{{ dialogData.buttons.cancel | translate }}</button>
    <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="isDisabled" *ngIf="!permissionService.hasPermission('main.auditorExcludeEditing')">
      {{ dialogData.buttons.accept | translate }}
    </button>
  </div>
</div>

import {InjectionToken} from '@angular/core';

/**
 * Interface declaring the types for the application environment config file.
 * This file is a typescript file and it's usually provided at build time by angular.json in the fileReplacements config field.
 */
export interface Environment {
  production: boolean;
  version: string;
  config?: ApplicationInsights;
}

/**
 * Interface declaring the types for the application configuration file.
 * This file is a JSON file and will be loaded before the application's bootstrap phase.
 * See `main.ts` for reference
 */
export interface Configuration {
  applicationInsights: ApplicationInsights;
  customerInsights: CustomerInsights;
}

/**
 * Interface declaring the types for the application insights (any config item that refers to the application global scope)
 */
export interface ApplicationInsights {
  appTitle: string;
  name: string;
  sentryEnvironmentName?: string;
  version: string;
  sentryDNS: string;
  googleJSAPIKey: string;
  polling?: boolean;
}

/**
 * Interface declaring the types for the customer insights (any config item that refers to a customer configuration)
 * This is a way to chop up large config items and also enable future module maintainability.
 * Modules can further have their scoped config items e.g.(Lib => libRequiredConfigItem)
 */
export interface CustomerInsights {
  serverUrl: string;
  currency: { code: string; locale: string };
  locale: string;
  scanbotLicenseKey?: string;
  googleApiKey?: string;
  addressAutocompleteRestrictions?: Array<string>;
  whitelistedDomains: Array<string>;
  authType?: string;
  msAuth?: any;
  selfServiceUrl?: any;
}

export interface CoreInformation {
  resources?: string;
  version?: string;
}

export const ENVIRONMENT = new InjectionToken<Configuration>('environment');
export const APP_CONFIG = new InjectionToken<Configuration>('configuration');
export const APP_INSIGHTS_CONFIG = new InjectionToken<ApplicationInsights>('app-insights-config');
export const CUSTOMER_INSIGHTS_CONFIG = new InjectionToken<CustomerInsights>('customer-insights-config');
export const CORE_RESOURCES_VERSION = new InjectionToken<string>('core-resources-version');
export const CORE_VERSION = new InjectionToken<string>('core-version');
export const CORE_INFORMATION = new InjectionToken<CoreInformation>('core-information');

import {Component, EventEmitter, Inject, OnInit, Output, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormGroup, Validators} from '@angular/forms';
import { DialogData } from 'apps/brokerage-v1/src/app/shared/models/dialog/dialog.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ThirdPartyService } from '../../../third-party/services/third-party.service';

@Component({
  selector: 'app-loan-request-dialog',
  templateUrl: './loan-request-dialog.component.html',
  styleUrls: ['./loan-request-dialog.component.scss'],
})
export class LoanRequestDialogComponent implements OnInit, OnDestroy {
  @Output() buttonClicked = new EventEmitter<any>();
  formConfiguration;
  isDisabled: boolean;
  form: FormGroup;
  formConfigurationErrors: Array<string> = [];
  formConfigurationWarnings: Array<string> = [];
	private readonly _destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<LoanRequestDialogComponent>,
    private thirdPartyService: ThirdPartyService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) {
  }

  ngOnInit() {
    this.isDisabled = !(this.dialogData.startAsValid || false);
    this.dialogData = new DialogData(this.dialogData);
    this.fillForm();
  }

  ngOnDestroy(): void {
		this._destroy$.next(undefined);
		this._destroy$.complete();
	} 

  fillForm() {
    this.formConfiguration = this.dialogData.formConfiguration;
    // this.questions = this.dynamicForm.create(); // INSERT WHEN SERVICE IS AVAILABLE
    if (this.formConfiguration === undefined || this.formConfiguration === null) {
      this.isDisabled = false;
      this.form = new FormGroup({});
    }
  }

  getFormConfigurationErrors(errors) {
    this.formConfigurationErrors = errors;
  }

  getFormConfigurationWarnings(warnings) {
    this.formConfigurationWarnings = warnings;
  }

  formValueChanged(form) {
    this.form = form;
    this.isDisabled = !this.form.valid;
  }

  formValueInit(form) {
    this.form = form;
    this.isDisabled = !this.form.valid;
    this.form.controls['broker'].valueChanges.pipe(takeUntil(this._destroy$)).subscribe((broker) => {
      this.getUserOptions(broker)
    })
  }

  getUserOptions(brokerId) {
    const formConfiguration = JSON.parse(JSON.stringify(this.formConfiguration))
    const createdByQuest = formConfiguration.formControl.questions.find(quest => quest.key == 'createdBy');
    const brokerQuest = formConfiguration.formControl.questions.find(quest => quest.key == 'broker')
    this.getBrokerUsers(brokerId).subscribe(result => {
      createdByQuest.options = result.map(res => {
        return {
          key: res.email,
          id: res.id
        } 
      })
      this.isDisabled = true;
      createdByQuest.value = '';
      createdByQuest.validators = [Validators.required];
      brokerQuest.value = brokerId;
      this.formConfiguration = formConfiguration
    })
  }

  getBrokerUsers(id) {
    return this.thirdPartyService.getBrokerUsers(id);
  }

  onSubmit() {
    this.dialogRef.close(this.form);
  }

  onButtonClicked() {
    this.buttonClicked.emit(this.form);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}

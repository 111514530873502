import {NgModule, ErrorHandler, LOCALE_ID} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeNLBE from '@angular/common/locales/nl-BE';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {AgmCoreModule, LAZY_MAPS_API_CONFIG} from '@agm/core';

import {
  ENVIRONMENT,
  APP_CONFIG,
  APP_INSIGHTS_CONFIG,
  CUSTOMER_INSIGHTS_CONFIG,
  CORE_VERSION,
  CORE_INFORMATION,
  Configuration,
  CoreInformation,
} from '@oper-brokerage-v1/shared/configuration';
import {SentryErrorHandler} from './core/services/error-proxy-forwarding.service';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule, HttpLoaderFactory} from './core/core.module';
import {LoadInterceptor} from './core/interceptors/load.interceptor';

import {ClientModule} from './features/client/client.module';
import {LoanRequestModule} from './features/loan-request/loan-request.module';
import {TaskModule} from './features/task/task.module';
import {SharedModule} from './shared/shared.module';
import {GoogleMapsConfig} from '../app/shared/services/google-maps/google-maps.config';
import {CommissionModule} from "./features/commissions/commission.module";

registerLocaleData(localeNLBE, 'nl-BE');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    ClientModule,
    LoanRequestModule,
    CommissionModule,
    TaskModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot(),
    MatGoogleMapsAutocompleteModule,
  ],
  providers: [
    {provide: ENVIRONMENT, useValue: environment},
    {
      provide: APP_INSIGHTS_CONFIG,
      useFactory: (config: Configuration) => config.applicationInsights,
      deps: [APP_CONFIG]
    },
    {
      provide: CUSTOMER_INSIGHTS_CONFIG,
      useFactory: (config: Configuration) => config.customerInsights,
      deps: [APP_CONFIG]
    },
    {provide: CORE_VERSION, useFactory: (info: CoreInformation) => info.version, deps: [CORE_INFORMATION]},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadInterceptor,
      multi: true,
    },

    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: LOCALE_ID, useValue: 'nl-BE'},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public isArray = function (array) {
    return Array.isArray(array);
  };

  public isObject = function (object) {
    return object === Object(object) && !this.isArray(object) && typeof object !== 'function';
  };

  public erectObject(shrunkenObject) {
    if (Object(shrunkenObject) !== shrunkenObject || Array.isArray(shrunkenObject)) {
      return shrunkenObject;
    }
    const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
    const resultHolder = {};
    for (const p in shrunkenObject) {
      if (p) {
        let cur = resultHolder;
        let prop = '';
        let m;
        while ((m = regex.exec(p))) {
          cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
          prop = m[2] || m[1];
        }
        cur[prop] = shrunkenObject[p];
      }
    }
    return resultHolder[''] || resultHolder;
  }
}

<div class="outerContainer">
  <div class="colorContainer" [ngClass]="snackbarClass"></div>
  <div class="messageContainer">
    <span>{{ this.message | translate }}</span>
  </div>
  <div class="actionContainer">
    <button mat-flat-button (click)="dismiss()">
      <span>{{ this.action | translate | uppercase }}</span>
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { ResourceService } from '../../../shared/services/resource/resource.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { InputField, InputSelect } from '../../../shared/models/dynamic-form/input-types.model';
import { Validators } from '@angular/forms';
import { ValidatorService } from '../../../shared/services/validator/validator.service';
import { NewsflashStatement, NewsPagination } from '../models/newsflash-statement.model';

@Injectable({
	providedIn: 'root',
})
export class NewsflashService {
	constructor(private apiService: ApiService, private resourceService: ResourceService, private validatorService: ValidatorService) {}

	public getNews(pagination: NewsPagination) {
		return this.apiService.get(`/api/news/`, this.buildParams(pagination));
	}

	public getNew(id: number) {
		return this.apiService.get(`/api/news/${id}`);
	}

	public deleteNewslash(id: number) {
		return this.apiService.delete(`/api/news/${id}`);
	}

	public createNewslash(body: any) {
		return this.apiService.post(`/api/news/`, this.getCorrectNewType(body));
	}

	getCorrectNewType(body) {
		if (typeof body.newsType === 'number') {
			const newsType = {
				id: body.newsType,
				definition: this.resourceService.getResourceInstances('news-type').find((type) => type.id === body.newsType).definition,
			};
			body = {
				...body,
				newsType: newsType,
			};
		}
		return body;
	}

	public updateNewflash(id: number, body: any) {
		return this.apiService.put(`/api/news/${id}/`, this.getCorrectNewType(body));
	}

	public uploadFile(id: number, file, language: string) {
		const payload = new FormData();
		payload.append('file', file, file.name || '');
		const headers = new HttpHeaders().set('Content-type', 'multipart/form-data');
		return this.apiService.post(`/api/news/${id}/attachments/`, payload, {
			params: {
				lang: language,
			},
			headers,
		});
	}

	public getFile(id: number) {
		return this.apiService.get(`/api/news/${id}/attachments/`);
	}

	public deleteDocument(idNewflash: number, idDocument: number) {
		return this.apiService.delete(`/api/news/${idNewflash}/attachments/${idDocument}`);
	}

	private buildParams(pagination) {
		let params = new HttpParams().set('page', `${pagination.page}`).set('page_size', `${pagination.pageSize}`);
		if (pagination.confirmed !== undefined) {
			params = params.set('confirmed', `${pagination.confirmed}`);
		}
		if (pagination.needConfirmation !== undefined) {
			params = params.set('need_confirmation', `${pagination.needConfirmation}`);
		}
		return params;
	}

	public confirmNew(id: number) {
		return this.apiService.post(`/api/news/${id}/confirm/`);
	}

  public getBrokerAccessGroups() {
    return this.apiService.get(`/api/broker-access-groups/`);
  }

	public isFileTypeAllowed(fileType: string) {
		return (fileType.startsWith('image') && fileType !== 'image/gif') || fileType === 'application/pdf';
	}

	public getNewflashQuestion(newflash = new NewsflashStatement(), brokerAccessGroups) {
		const options = this.resourceService.getResourceInstances('news-type').map((type) => {
			return {
				key: type.key,
				id: type.id,
			};
		});
    const brokerAccessGroup = brokerAccessGroups.map((group) => {
      return {
        key: group.name,
        id: group.id,
      };
    });
    const blacklistedBrokerAccessGroup = brokerAccessGroups.map((group) => {
      return {
        key: group.name,
        id: group.id,
      };
    });
		const questions = [
			new InputField({
				key: 'titleNl',
				label: 'ç.feature.newsflash.form.titleNl',
				value: newflash.titleNl || '',
				type: '',
				class: 'span6',
				validators: [Validators.required, this.validatorService.maxSimbolValidator(128)],
				order: 1,
			}),
			new InputField({
				key: 'titleFr',
				label: 'ç.feature.newsflash.form.titleFr',
				value: newflash.titleFr || '',
				type: '',
				class: 'span6',
				validators: [Validators.required, this.validatorService.maxSimbolValidator(128)],
				order: 2,
			}),
			new InputField({
				key: 'bodyNl',
				label: 'ç.feature.newsflash.form.bodyNl',
				value: newflash.bodyNl || '',
				multiline: true,
				type: 'text',
				class: 'span6',
				validators: [Validators.required],
				order: 3,
			}),
			new InputField({
				key: 'bodyFr',
				label: 'ç.feature.newsflash.form.bodyFr',
				value: newflash.bodyFr || '',
				multiline: true,
				type: 'text',
				class: 'span6',
				validators: [Validators.required],
				order: 4,
			}),
			new InputField({
				key: 'publishStartDate',
				label: 'ç.feature.newsflash.form.publishStartDate',
				value: newflash?.publishStartDate || null,
				type: 'date',
				validators: [],
				class: 'span3',
				required: false,
				order: 9,
			}),
			new InputField({
				key: 'publishEndDate',
				label: 'ç.feature.newsflash.form.publishEndDate',
				value: newflash.publishEndDate || null,
				type: 'date',
				required: false,
				validators: [],
				class: 'span3',
				order: 10,
			}),
			new InputSelect({
				key: 'newsType',
				label: 'ç.feature.newsflash.form.newsType',
				options: options,
				value: newflash.newsType?.id || 1,
				class: 'span3',
				validators: [Validators.required],
				order: 11,
			}),
			new InputSelect({
				key: 'confirmationFlag',
				label: 'ç.feature.newsflash.form.confirmationFlag',
				options: [
					{
						key: 'ç.misc.action.yes',
						id: true,
					},
					{
						key: 'ç.misc.action.no',
						id: false,
					},
				],
				value: newflash.confirmationFlag !== null ? newflash.confirmationFlag : false,
				class: 'span3',
				validators: [Validators.required],
				order: 12,
			}),
			new InputSelect({
				key: 'visibility',
				label: 'ç.feature.newsflash.form.visibility',
				options: [
					{
						key: 'ç.misc.action.yes',
						id: true,
					},
					{
						key: 'ç.misc.action.no',
						id: false,
					},
				],
				value: newflash.visibility !== null ? newflash.visibility : true,
				class: 'span3',
				validators: [Validators.required],
				order: 13,
			}),
      new InputSelect({
        key: 'brokerAccessGroup',
        label: 'Broker Access Group',
        options: brokerAccessGroup.concat(({key: 'None', value: null})),
        value: newflash.brokerAccessGroup,
        class: 'span3',
        order: 14,
        validators: [],
        required: false
      }),
      new InputSelect({
        key: 'blacklistedBrokerAccessGroup',
        label: 'Blacklisted Broker Access Group',
        options: blacklistedBrokerAccessGroup.concat(({key: 'None', value: null})),
        value: newflash.blacklistedBrokerAccessGroup,
        class: 'span3',
        order: 15,
        validators: [],
        required: false
      }),
		];

		return questions;
	}
}

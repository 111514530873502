import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {FeatureFlagService} from '../../../core/services/feature-flag.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public featureFlagService: FeatureFlagService
  ) {
  }

  file: File = null;

  onClick(id) {
    this.bottomSheetRef.dismiss(id);
  }

  selectFile(event: MouseEvent): void {
    this.file = event.target['files'][0];
    this.bottomSheetRef.dismiss(this.file);
    // event.preventDefault();
  }
}

import { Address, Email, PhoneNumber } from '../../client/models/client.model';
import { Resource } from '../../../shared/models/resource/resource.model';

export class ContactPerson {
	id: number;
	firstName: string;
	lastName: string;
	role: Resource;
	addresses: Address[];
	emails: Email[];
	phoneNumbers: PhoneNumber[];

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || null;
			this.firstName = data.firstName || '';
			this.lastName = data.lastName || '';
			this.role = new Resource(data.role);
			this.addresses = data.addresses ?? [];
			this.emails = data.emails ?? [];
			this.phoneNumbers = data.phoneNumbers ?? [];
		}
	}
}

export class Notary {
	id: string;
	name: string;
	fsmaStatus: Resource;
	companyType: Resource;
	accountManager: ContactPerson;
	mainContact: ContactPerson;
	vatNumber: string;
	bankAccount: string;
	addresses: Address[];
	emails: Email[];
	phoneNumbers: PhoneNumber[];
	commissionEmail: Email;
	commissionClass: Resource;
	confiscateCommission: boolean;
	belnotReference: string;
	profile: string;

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || undefined;
			this.name = data.name || '';
			this.accountManager = new ContactPerson(data.accountManager);
			this.mainContact = new ContactPerson(data.mainContact);
			this.companyType = new Resource(data.companyType);
			this.fsmaStatus = new Resource(data.fsmaStatus);
			this.vatNumber = data.vatNumber || '';
			this.bankAccount = data.bankAccount || '';
			this.addresses = data.addresses ?? [];
			this.emails = data.emails ?? [];
			this.phoneNumbers = data.phoneNumbers ?? [];
			this.commissionEmail = new Email(data.commissionEmail);
			this.commissionClass = new Resource(data.commissionClass);
			this.confiscateCommission = data.confiscateCommission || undefined;
			this.belnotReference = data.belnotReference || '';
			this.profile = data.profile || '';
		}
	}
}

export class NotaryItem {
	id: string;
	name: string;
	vatNumber: string;
	bankAccount: string;
	addresses: Address[];
	emails: Email[];

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || undefined;
			this.name = data.name || '';
			this.vatNumber = data.vatNumber || '';
			this.bankAccount = data.bankAccount || '';
			this.addresses = data.addresses ?? [];
			this.emails = data.emails ?? [];
		}
	}
}

export enum Region {
  CH = 'CH',
}

export enum DateFormat {
  FULL_DATE_DOT = 'dd.MM.yyyy',
  FULL_DATE_SLASH = 'dd/MM/yyyy',
  MONTH_YEAR_FORMAT_DOT = 'MM.yyyy',
  MONTH_YEAR_FORMAT_SLASH = 'MM/yyyy',
  YEAR_FORMAT = 'yyyy',
  DATETIMEFORMAT = 'dd/MM/yyyy - HH:mm:ss',
}

export class AppDate {
  static readonly DAY_FORMAT = 'EEEE';
  static readonly TIME_FORMAT = 'hh:mm';

  public static getDateFormat(value: string, region: string) {
    const length: number = value.length;
    switch (length) {
      case 4:
        return DateFormat.YEAR_FORMAT;
      case 7:
        if (region === Region.CH) {
          return DateFormat.MONTH_YEAR_FORMAT_DOT;
        }
        return DateFormat.MONTH_YEAR_FORMAT_SLASH;
      default:
        if (region === Region.CH) {
          return DateFormat.FULL_DATE_DOT;
        }
        return DateFormat.FULL_DATE_SLASH;
    }
  }
}

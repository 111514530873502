<div *ngIf="form.controls[question.key]" [formGroup]="form">
  <ng-container *ngIf="question.controlType !== 'submit'">
    <mat-icon
      *ngIf="!isValid && form.controls[question.key].touched && getErrors()"
      matTooltip="{{ getErrors().join('. ') }}"
      class="mat-iconError"
    >info
    </mat-icon>
    <mat-form-field 
      *ngIf="question.controlType !== 'date'" 
      appearance="outline" 
      [floatLabel]="question.specialDisabledStyle ? 'always' :  'auto'"
      class="mat-form-field"
      [ngClass]="{
        'readonly-block': question.disabled,
        'specialDisabled': question.specialDisabledStyle
      }" 
    >
      <mat-label [attr.for]="question.key">{{ question.label | translate }}</mat-label>
      <div [ngSwitch]="question.controlType">
        <!-- input -->
        <ng-container *ngSwitchCase="'field'">
          <ng-container *ngIf="!question.multiline">
            <input
              (blur)="onBlur()"
              (keydown.backspace)="onBackspace($event, question.mask)"
              (ngModelChange)="onTextChange($event, question.mask)"
              matInput
              [id]="question.key"
              [formControlName]="question.key"
              [type]="question.type"
              [required]="question.required"
              ng-disabled="question.disabled"
              [readonly]="question.disabled"
              min="0"
              [tabIndex]="question.disabled ? -1 : null"
            />
          </ng-container>
          <ng-container *ngIf="question.multiline">
						<textarea
              (blur)="onBlur()"
              (keydown.backspace)="onBackspace($event, question.mask)"
              (ngModelChange)="onTextChange($event, question.mask)"
              matInput
              [id]="question.key"
              [formControlName]="question.key"
              [type]="question.type"
              [required]="question.required"
              ng-disabled="question.disabled"
              min="0"
              rows="4"
              [tabIndex]="question.disabled ? -1 : null"
            >
						</textarea>
          </ng-container>
        </ng-container>

        <!-- select -->
        <ng-container *ngSwitchCase="'select'">
          <!-- not searchable -->
          <ng-container *ngIf="!question.searchable">
            <mat-select
              [id]="question.key"
              [formControlName]="question.key"
              (selectionChange)="onSelectionChange()"
              [value]="question.value"
              [multiple]="question.multiple"
              [required]="question.required"
              [disabled]="question.disabled && !permissionService.hasPermission('main.auditorExcludeEditing')"
              [tabIndex]="question.disabled ? -1 : null"
            >
              <ng-container *ngFor="let option of question.options">
                <mat-option *ngIf="option.key === 'None'">{{ option.key | translate }} </mat-option>
              </ng-container>
              <ng-container *ngFor="let option of question.options">
                <mat-option *ngIf="option.key !== 'None'" [value]="option.id">{{ option.key | translate }} </mat-option>
              </ng-container>
            </mat-select>
          </ng-container>
          <!-- searchable -->
          <ng-container *ngIf="question.searchable">
            <mat-select
              #singleSelect
              [formControlName]="question.key"
              [required]="question.required"
              ng-disabled="question.disabled && !permissionService.hasPermission('main.auditorExcludeEditing')"
              (selectionChange)="onSelectionChange($event)"
              [disabled]="question.specialDisabledStyle"
              [tabIndex]="question.disabled ? -1 : null"
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="filterFormControl"
                  (ngModelChange)="onTextChange($event)"
                  [noEntriesFoundLabel]="'ç.misc.nothingFound' | translate"
                  placeholderLabel=""
                >
                </ngx-mat-select-search>
              </mat-option>
              <ng-container *ngFor="let option of filteredOptions">
                <mat-option *ngIf="option.key === 'None'">{{ option.key | translate }} </mat-option>
              </ng-container>
              <ng-container *ngFor="let option of filteredOptions">
                <mat-option *ngIf="option.key !== 'None'" [value]="option.id">
                  {{ option.key | translate }}
                </mat-option>
              </ng-container>
            </mat-select>
          </ng-container>
        </ng-container>

        <!-- place -->
        <input
          matInput
          matGoogleMapsAutocomplete
          [id]="question.key"
          *ngSwitchCase="'place'"
          placeholder=""
          [formControlName]="question.key"
          [required]="question.required"
          ng-disabled="question.disabled"
          (ngModelChange)="onTextChange($event, question.mask)"
          (onAutocompleteSelected)="onAutocompleteSelected($event)"
          [tabIndex]="question.disabled ? -1 : null"
        />

        <!-- chip list -->
        <mat-chip-list
          ng-disabled="question.disabled"
          #chipList
          *ngSwitchCase="'chips'"
          [required]="question.required"
          ngDefaultControl
          [formControlName]="question.key"
          [id]="question.key"
          [tabIndex]="question.disabled ? -1 : null"
        >
          <mat-chip
            *ngFor="let item of question.value; let i = index"
            [selectable]="question.selectable"
            [removable]="question.removable"
            (removed)="removeChip(item, i)"
          >
            {{ item }}
            <mat-icon matChipRemove *ngIf="question.removable">cancel</mat-icon>
          </mat-chip>
          <input
            #chipInput
            (blur)="addChip(chipInput, true)"
            matInput
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="question.separators"
            (matChipInputTokenEnd)="addChip($event)"
            [type]="question.type"
            [tabIndex]="question.disabled ? -1 : null"
          />
        </mat-chip-list>

        <!-- spacer -->
        <input 
            matInput 
            [id]="'spacer'" 
            *ngSwitchCase="'spacer'" 
            [formControlName]="question.key" 
            ng-disabled="true" 
            [tabIndex]="question.disabled ? -1 : null"
          />
      </div>
      <p *ngIf="question.prefix" class="prefix" matPrefix>{{ question.prefix }}</p>
      <p *ngIf="question.suffix" class="suffix" matSuffix>{{ question.suffix }}</p>
    </mat-form-field>
    <ng-container *ngIf="question.controlType === 'date'">
      <app-date-picker [question]="question" [form]="form" (valueChange)="onDateChange($event)">
      </app-date-picker>
    </ng-container>
  </ng-container>

  <!-- <button *ngIf="question.controlType==='submit'" mat-flat-button type='submit' color='primary' (click)=onButtonClicked()>
    <mat-icon class="mat-icon">add_circle</mat-icon>
  </button> -->

  <button
    *ngIf="question.controlType === 'submit'"
    [disabled]="question.disabled"
    mat-stroked-button
    type="submit"
    color="primary"
    (click)="onButtonClick()"
  >
    <ng-container *ngIf="question.iconName">
      <i class="uil {{ question.iconName }}"></i>
    </ng-container>
    {{ question.label | translate }}
  </button>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

import {LoanRequestListComponent} from './components/loan-request-list/loan-request-list.component';
import {LoanRequestListItemComponent} from './components/loan-request-list-item/loan-request-list-item.component';

import {MaterialModule} from '../../material/material.module';
import {SharedModule} from '../../shared/shared.module';
import {DateFormatPipe} from '../../shared/pipe/date-format/date-format.pipe';
import {
  LoanRequestConfigurationDialogComponent
} from './components/loan-request-configuration-dialog/loan-request-configuration-dialog.component';
import { LoanRequestDialogComponent } from './components/loan-request-dialog/loan-request-dialog.component';

@NgModule({
  imports: [CommonModule, SharedModule, TranslateModule, MaterialModule],
  declarations: [LoanRequestListComponent, LoanRequestListItemComponent, LoanRequestConfigurationDialogComponent,LoanRequestDialogComponent],
  exports: [LoanRequestListComponent, LoanRequestListItemComponent],
  providers: [DateFormatPipe],
})
export class LoanRequestModule {
}

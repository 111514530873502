import {Inject, Injectable} from '@angular/core';
import {ApplicationInsights, APP_INSIGHTS_CONFIG} from '@oper-brokerage-v1/shared/configuration';

@Injectable()
export class GoogleMapsConfig {
  apiKey: string;
  libraries = ['places'];

  constructor(@Inject(APP_INSIGHTS_CONFIG) private readonly applicationInsights: ApplicationInsights) {
    this.apiKey = applicationInsights.googleJSAPIKey;
  }
}

import {Resource} from '../../../shared/models/resource/resource.model';
import {User} from '../../user/models/user.model';

class Address {
  street: string;
  houseNumber: string;
  box: string;
  zipCode: string;
  city: string;
  country: string;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.street = data.street || '';
      this.houseNumber = data.houseNumber || '';
      this.box = data.box || '';
      this.zipCode = data.zipCode || '';
      this.city = data.city || '';
      this.country = data.country || '';
    }
  }
}

export class Document {
  id: number;
  documentType: Resource;
  status: Resource;
  mimeType: string;
  url: string;
  generatedDocument: Document;
  validFrom: string;
  version: number;
  audience: Resource;
  context: string;
  created: Date;
  fileName: string;
  rejectionReason: string;
  assessmentBy: User;
  assessmentDate: string;
  hasDocument: boolean;
  hasGeneratedDocument: boolean;
  order: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.documentType = Resource.createResource(data.documentType);
      this.status = Resource.createResource(data.status);
      this.mimeType = data.mimeType || '';
      this.url = data.url || '';
      this.generatedDocument = data.generatedDocument ? new Document(data.generatedDocument) : null;
      this.validFrom = data.validFrom || '';
      this.version = data.version || null;
      this.audience = Resource.createResource(data.audience);
      this.context = data.context || '';
      this.created = new Date(data.created) || null;
      this.fileName = data.fileName || '';
      this.rejectionReason = data.rejectionReason || '';
      this.assessmentBy = data.assessmentBy ? new User(data.assessmentBy) : null;
      this.assessmentDate = data.assessmentDate || '';
      this.hasDocument = data.hasDocument || null;
      this.hasGeneratedDocument = data.hasGeneratedDocument || null;
    }
  }
}

export class AnalysisDocument extends Document {
  analysisStatus: Resource;

  constructor(data?) {
    super(data);
    if (!(data === undefined || data === null)) {
      this.analysisStatus = new Resource(data.analysisStatus);
    }
  }
}

export class ContractFitDocument extends AnalysisDocument {
  contractFitUrl: string;

  constructor(data?) {
    super(data);
    if (!(data === undefined || data === null)) {
      this.contractFitUrl = data.contractFitUrl || '';
    }
  }
}

// TODO: add all meta data when back end is finished
export class Meta {
  // Client
  firstName: string;
  lastName: string;

  // Realty
  address: Address;

  // Loan request
  internalReference: string;

  // Liability
  liabilityType: Resource;
  periodicity: Resource;
  startDate: Date;
  amount: number;
  provider: Object;
  client: Object;
  monthlyAmount: number;

  // Insurances
  realty: Object;
  insuredAmount: number;
  premium: number;
  policyNumber: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.firstName = data.firstName || '';
      this.lastName = data.lastName || '';
      this.address = new Address(data.address);
      this.internalReference = data.internalReference || '';
      this.liabilityType = new Resource(data.liabilityType);
      this.periodicity = new Resource(data.periodicity);
      this.startDate = data.startDate || '';
      this.amount = data.amount || 0;
      this.provider = data.provider;
      this.client = data.client || null;
      this.monthlyAmount = data.monthlyAmount || 0;
      this.realty = data.realty || null;
      this.insuredAmount = data.insuredAmount || 0;
      this.premium = data.premium || 0;
      this.policyNumber = data.policyNumber || '';
    }
  }

  static createMeta(data) {
    if (data) {
      return new Meta(data);
    } else {
      return null;
    }
  }
}

export class Entity {
  category: Resource;
  id: number;
  meta: any;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.category = new Resource(data.category);
      this.id = data.id || null;
      // TODO: link to meta data model when back end is finished
      this.meta = data.meta || null;
    }
  }

  static createEntity(data) {
    if (data) {
      return new Entity(data);
    } else {
      return null;
    }
  }
}

export class Proof {
  id: number;
  entity: Entity;
  documents: Array<Document>;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.entity = Entity.createEntity(data.entity);
      this.documents = [];
      if (!(data.documents === undefined)) {
        data.documents.forEach(document => {
          this.documents.push(new Document(document));
        });
      }
    }
  }
}

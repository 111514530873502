import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {TaskTableComponent} from './components/task-table/task-table.component';
import {TaskPluginComponent} from './components/task-plugin/task-plugin.component';
import {TaskBasicStatusFilterComponent} from './components/task-basic-status-filter/task-basic-status-filter.component';
import {TaskSearchComponent} from './components/task-search/task-search.component';
import {TaskFilterComponent} from './components/task-filter/task-filter.component';
import {TaskAddComponent} from './components/task-add/task-add.component';
import {TaskTableItemComponent} from './components/task-table-item/task-table-item.component';

@NgModule({
  imports: [SharedModule, TranslateModule],
  declarations: [
    TaskTableComponent,
    TaskPluginComponent,
    TaskBasicStatusFilterComponent,
    TaskSearchComponent,
    TaskFilterComponent,
    TaskAddComponent,
    TaskTableItemComponent,
  ],
  exports: [TaskPluginComponent],
})
export class TaskModule {
}

import {Resource} from '../../../shared/models/resource/resource.model';
import {PhoneNumber} from '../../client/models/client.model';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  role: Resource;
  email: string;
  phoneNumbers: Array<PhoneNumber>;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.firstName = data.firstName || '';
      this.lastName = data.lastName || '';
      this.role = new Resource(data.role);
      this.email = data.email || '';
      this.phoneNumbers = [];
      if (!(data.phoneNumbers === undefined)) {
        data.phoneNumbers.forEach(phoneNumber => {
          this.phoneNumbers.push(new PhoneNumber(phoneNumber));
        });
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {CaseStyleService} from '../../shared/services/case-style/case-style.service';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor(private caseStyleService: CaseStyleService) {
  }

  public unpackToken(token: string) {
    // Get the second part of the JWT (the one with content)
    const data = atob(token.split('.')[1]);
    // And parse it to a JSON object
    return this.caseStyleService.convertKeysToCamelCase(JSON.parse(data));
  }
}

import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../models/dialog/dialog.model';
import {FormGroup} from '@angular/forms';
import { PermissionService } from '../../../core/services/permission.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Output() buttonClicked = new EventEmitter<any>();
  formConfiguration;
  isDisabled: boolean;
  form: FormGroup;
  formConfigurationErrors: Array<string> = [];
  formConfigurationWarnings: Array<string> = [];

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>, 
    public permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
    ) {
  }

  ngOnInit() {
    this.isDisabled = !(this.dialogData.startAsValid || false);
    this.dialogData = new DialogData(this.dialogData);
    this.fillForm();
  }

  fillForm() {
    this.formConfiguration = this.dialogData.formConfiguration;
    // this.questions = this.dynamicForm.create(); // INSERT WHEN SERVICE IS AVAILABLE
    if (this.formConfiguration === undefined || this.formConfiguration === null) {
      this.isDisabled = false;
      this.form = new FormGroup({});
    }
  }

  getFormConfigurationErrors(errors) {
    this.formConfigurationErrors = errors;
  }

  getFormConfigurationWarnings(warnings) {
    this.formConfigurationWarnings = warnings;
  }

  formValueChanged(form) {
    this.form = form;
    this.isDisabled = !this.form.valid;
  }

  formValueInit(form) {
    this.form = form;
    this.isDisabled = !this.form.valid;
  }

  onSubmit() {
    this.dialogRef.close(this.form);
  }

  onButtonClicked() {
    this.buttonClicked.emit(this.form);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}

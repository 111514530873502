import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {LiabilityComponent} from './liability/components/liability/liability.component';

@NgModule({
  imports: [SharedModule, TranslateModule],
  declarations: [LiabilityComponent],
})
export class ClientModule {
  constructor() {
  }
}

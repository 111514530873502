<div class="wrapper">
  <mat-label class="lab">{{ question.label | translate }}</mat-label>
  <mat-form-field 
    appearance="outline" 
    class="mat-form-field bd"
    [ngClass]="{'readonly-block': permissionService.hasPermission('main.auditorExcludeEditing')}" 
  >
    <mat-label for="day">{{'ç.misc.duration.day.singular' | translate }}</mat-label>
    <mat-select id="day" name="day" [(ngModel)]="_day" (selectionChange)="onDayChange()"    
    [tabIndex]="permissionService.hasPermission('main.auditorExcludeEditing') ? -1 : null">
      <ng-container *ngIf="question.searchable">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="filterFormControl"
            (ngModelChange)="filterDays($event)"
            [noEntriesFoundLabel]="'ç.misc.nothingFound' | translate"
            placeholderLabel=""
          >
          </ngx-mat-select-search>
        </mat-option>
      </ng-container>
      <mat-option>{{'ç.misc.duration.day.singular' | translate }}</mat-option>
      <mat-option *ngFor="let d of filteredDays" [value]="d">{{ d }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field 
    appearance="outline" 
    class="mat-form-field bd"  
    [ngClass]="{'readonly-block': permissionService.hasPermission('main.auditorExcludeEditing')}" 
  >
    <mat-label for="month">{{'ç.misc.duration.month.singular' | translate }}</mat-label>
    <mat-select id="month" name="month" [(ngModel)]="_month" (selectionChange)="onMonthChange()"     
    [tabIndex]="permissionService.hasPermission('main.auditorExcludeEditing') ? -1 : null">
      <ng-container *ngIf="question.searchable">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="filterFormControl"
            (ngModelChange)="filterMonths($event)"
            [noEntriesFoundLabel]="'ç.misc.nothingFound' | translate"
            placeholderLabel=""
          >
          </ngx-mat-select-search>
        </mat-option>
      </ng-container>
      <mat-option>{{'ç.misc.duration.month.singular' | translate }}</mat-option>
      <mat-option *ngFor="let m of filteredMonths" [value]="m">{{ m }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field 
    appearance="outline" 
    class="mat-form-field bd"
    [ngClass]="{'readonly-block': permissionService.hasPermission('main.auditorExcludeEditing')}" 
  >
    <mat-label for="year">{{'ç.misc.duration.year.singular' | translate }}</mat-label>
    <mat-select id="year" name="year" [(ngModel)]="_year" (selectionChange)="onYearChange()"
                [required]="question.required"
                [tabIndex]="tabindex">
      <ng-container *ngIf="question.searchable">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="filterFormControl"
            (ngModelChange)="filterYears($event)"
            [noEntriesFoundLabel]="'ç.misc.nothingFound' | translate"
            placeholderLabel=""
          >
          </ngx-mat-select-search>
        </mat-option>
      </ng-container>
      <mat-option>{{'ç.misc.duration.year.singular' | translate }}</mat-option>
      <mat-option *ngFor="let y of filteredYears" [value]="y">{{ y }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

import {Injectable, Inject} from '@angular/core';
import {CustomerInsights, CUSTOMER_INSIGHTS_CONFIG} from '@oper-brokerage-v1/shared/configuration';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  public locale: string;
  public language: string;
  public region: string;

  constructor(@Inject(CUSTOMER_INSIGHTS_CONFIG) private customerInsights: CustomerInsights) {
    this.locale = this.customerInsights.locale;
    this.language = this.locale.split('-')[0];
    this.region = this.locale.split('-')[1];
  }
}

import {Inject, Injectable} from '@angular/core';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';

import {CORE_VERSION} from '@oper-brokerage-v1/shared/configuration';

import {ApiService} from '../../../core/services/api.service';
import {Resource} from '../../models/resource/resource.model';
import {TranslateService} from '@ngx-translate/core';

const resourceOrder: { [key: string]: { definition: string; other?: boolean }[] } = {
  'country': [{definition: 'BE'}, {definition: 'DE'}, {definition: 'FR'}, {definition: 'LU'}, {definition: 'NL'}],
  'client-role': [
    {definition: 'borrower'},
    {definition: 'collateralProvider'},
    {definition: 'spouse'},
    {definition: 'thirdParty'},
  ],
  'employment-type': [
    {definition: 'employee'},
    {definition: 'worker'},
    {definition: 'civilServant'},
    {definition: 'unemployed'},
    {definition: 'retired'},
    {definition: 'earlyRetirement'},
    {definition: 'mutuality'},
    {definition: 'disabled'},
    {definition: 'healthInsuranceSettlement'},
    {definition: 'houseWife'},
    {definition: 'selfEmployedSoleProprietorship'},
    {definition: 'selfEmployedCompany'},
    {definition: 'diplomat'},
    {definition: 'student'},
    {definition: 'unclear'},
  ],
  'civil-status': [
    {definition: 'marriedLawful'},
    {definition: 'marriedSeparateGoods'},
    {definition: 'marriedCombinedGoods'},
    {definition: 'marriedForeignSystem'},
    {definition: 'marriedLivingSeparate'},
    {definition: 'cohabiting'},
    {definition: 'cohabitingWithContract'},
    {definition: 'divorced'},
    {definition: 'defactoDivorced'},
    {definition: 'single'},
    {definition: 'widow'},
  ],
  'income-type': [
    {definition: 'salaryEmployedPayrollBorrower'},
    {definition: 'salaryEmployedCompanyOwner'},
    {definition: 'salarySelfEmployed'},
    {definition: 'unemploymentBenefit'},
    {definition: 'retirement'},
    {definition: 'healthInsurance'},
    {definition: 'invalidity'},
    {definition: 'rent'},
    {definition: 'futureRent'},
    {definition: 'mealVouchers'},
    {definition: 'alimony'},
    {definition: 'alimonyPersonal'},
    {definition: 'childBenefit'},
    {definition: 'other', other: true},
  ],
  'sector': [{definition: 'other', other: true}],
  'liability-type': [
    {definition: 'mortgageImmovable'},
    {definition: 'mortgageMovable'},
    {definition: 'consumerLoanImmovable'},
    {definition: 'consumerLoanMovable'},
    {definition: 'purchaseCredit'},
    {definition: 'creditCard'},
    {definition: 'creditLine'},
    {definition: 'rent'},
    {definition: 'leasing'},
    {definition: 'investmentCredit'},
    {definition: 'alimony'},
    {definition: 'otherCredit'},
  ],
  'regularity-type': [
    {definition: 'regular'},
    {definition: 'regularized'},
    {definition: 'zeroedOut'},
    {definition: 'openNotification'},
  ],
  'loan-request-purpose': [
    {definition: 'refinance'},
    {definition: 'purchase'},
    {definition: 'buyOut'},
    {definition: 'renovation'},
    {definition: 'liquidities'},
    {definition: 'donationTaxes'},
    {definition: 'heritageTaxes'},
    {definition: 'other'},
  ],
  'realty-type': [
    {definition: 'house'},
    {definition: 'villa'},
    {definition: 'apartment'},
    {definition: 'apartmentComplex'},
    {definition: 'studio'},
    {definition: 'investmentProperty'},
    {definition: 'commercialBuilding'},
    {definition: 'commercialGroundFloor'},
    {definition: 'office'},
    {definition: 'mixedBuilding'},
    {definition: 'studentRooms'},
    {definition: 'constructionLand'},
    {definition: 'constructionLandAndTurnKey'},
    {definition: 'garage'},
    {definition: 'chalet'},
    {definition: 'chaletNoDomicile'},
    {definition: 'agriculturalLand'},
    {definition: 'warehouse'},
    {definition: 'other'},
  ],
  'realty-purpose': [
    {definition: 'buy'},
    {definition: 'buyOut'},
    {definition: 'sell'},
    {definition: 'collateral'},
    {definition: 'renovate'},
  ],
  'realty-acquisition-type': [
    {definition: 'purchase'},
    {definition: 'buyOut'},
    {definition: 'gift'},
    {definition: 'heritage'},
    {definition: 'definitiveAllocation'},
    {definition: 'other'},
  ],
  'realty-usage-type': [
    {definition: 'living'},
    {definition: 'secondStay'},
    {definition: 'privateRent'},
    {definition: 'professionalRent'},
    {definition: 'commercial'},
    {definition: 'other'},
  ],
  'ownership-type': [
    {definition: 'fullOwnership'},
    {definition: 'freeHold'},
    {definition: 'usufruct'},
    {definition: 'undivided'},
    {definition: 'groundLease'},
    {definition: 'buildingRight'},
  ],
  'periodicity': [
    {definition: 'monthly'},
    {definition: 'quarterly'},
    {definition: 'semestrial'},
    {definition: 'yearly'},
    {definition: 'oneShot'},
  ],
  'additional-financing-need-type': [
    {definition: 'familyDebt'},
    {definition: 'purchaseCar'},
    {definition: 'executedRenovations'},
    {definition: 'heritageOrDonationCosts'},
    {definition: 'heritageTaxesMovable'},
    {definition: 'heritageTaxesImmovable'},
    {definition: 'donationTaxesImmovable'},
    {definition: 'personalTaxes'},
    {definition: 'cityTaxes'},
    {definition: 'registrationRightsAndNotaryCosts'},
    {definition: 'handlingCosts'},
  ],
  'credit-provider': [{definition: 'other', other: true}],
  'document-type': [],
  'task-type': [],
};

const defaults = {
  country: {definition: 'BE'},
};

export const sortByOrder = (key: string, translateService: TranslateService) => {
  return (a, b) => {
    const indexA = resourceOrder[key]?.findIndex(def => def.definition === a?.definition);

    const indexB = resourceOrder[key]?.findIndex(def => def.definition === b?.definition);
    if (indexA === indexB) {
      return (translateService.instant(a.key) as string).localeCompare(translateService.instant(b.key) as string);
    }
    if (indexA !== -1 && resourceOrder[key][indexA].other) {
      return 1;
    }
    if (indexB !== -1 && resourceOrder[key][indexB].other) {
      return -1;
    }
    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }
    const diff = indexA - indexB;
    return diff !== 0 ? diff / Math.abs(diff) : diff;
  };
};

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  resources: object = {};

  constructor(
    private apiService: ApiService,
    @Inject(CORE_VERSION) private coreVersion: string,
    private translateService: TranslateService
  ) {
  }

  public initResources() {
    return forkJoin([
      this.apiService.get('/resources/additional-financing-need-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/analysis-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/city/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/civil-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/client-role/?cache_version=' + this.coreVersion),

      this.apiService.get('/resources/client-legal-entity-role/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/comment-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/communication-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/company-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/contact-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/country/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/credit-provider/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/credit-takeover/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/decision-outcome/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/decision-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/document-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/document-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/employment-contract-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/employment-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/history-log-level/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/history-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/income-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/insurance-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/language/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/liability-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/loan-request-purpose/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/loan-type/?cache_version=' + this.coreVersion),
      // this.apiService.get('/resources/nbb-reply-status/?cache_version=' + this.coreVersion),
      // this.apiService.get('/resources/nbb-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/ownership-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/periodicity/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/phone-country-code/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/proof-audience/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/proof-category/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/proof-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/realty-acquisition-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/realty-purpose/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/realty-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/realty-usage-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/regularity-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/relationship/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/renovation-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/refinance-amount-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/build-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/sector/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/statute/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/sex/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/signature-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/user-role/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/variability?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/task-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/task-category/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/task-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/library-category/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/new-realty-option/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/news-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/commission-file-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/recovery-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/commission-statement-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/commission-class/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/fsma-status/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/broker-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/own-funds-type/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/a-card-details/?cache_version=' + this.coreVersion),
      this.apiService.get('/resources/rental-type/'),
    ]).pipe(
      map(
        ([
           additionalFinancingNeedType,
           analysisStatus,
           city,
           civilStatus,
           clientRole,
           clientLegalEntityRole,
           commentType,
           communicationType,
           companyType,
           contactType,
           country,
           creditProvider,
           creditTakeover,
           decisionOutcome,
           decisionType,
           documentStatus,
           documentType,
           employmentContractType,
           employmentType,
           historyLogLevel,
           historyType,
           incomeType,
           insuranceType,
           language,
           liabilityType,
           loanRequestPurpose,
           loanType,
           // nbbReplyStatus,
           // nbbType,
           ownershipType,
           periodicity,
           phoneCountryCode,
           proofAudience,
           proofCategory,
           proofStatus,
           realtyAcquisitionType,
           realtyPurpose,
           realtyType,
           realtyUsageType,
           regularityType,
           relationship,
           renovationType,
           refinanceAmountType,
           buildType,
           sector,
           statute,
           sex,
           signatureType,
           status,
           userRole,
           variability,
           taskStatus,
           taskCategory,
           taskType,
           libraryCategory,
           newRealty,
           newsType,
           commissionFileStatus,
           recoveryStatus,
           commissionStatementStatus,
           commissionClass,
           fsmaStatus,
           brokerType,
           ownFundsType,
           aCardDetails,
           rentalType
         ]) => {
          this.resources = {
            'additional-financing-need-type': additionalFinancingNeedType,
            'analysis-status': analysisStatus,
            'city': city,
            'civil-status': civilStatus,
            'client-role': clientRole,
            'client-legal-entity-role': clientLegalEntityRole,
            'comment-type': commentType,
            'communication-type': communicationType,
            'company-type': companyType,
            'contact-type': contactType,
            'country': country,
            'credit-provider': creditProvider.filter(res => res.definition !== 'creafinСompartment2' && res.definition !== 'creafinСompartment3' && res.definition !== 'creafinСompartment4'),
            'credit-takeover': creditTakeover,
            'decision-outcome': decisionOutcome,
            'decision-type': decisionType,
            'document-status': documentStatus,
            'document-type': documentType,
            'employment-contract-type': employmentContractType,
            'employment-type': employmentType,
            'history-log-level': historyLogLevel,
            'history-type': historyType,
            'income-type': incomeType,
            'insurance-type': insuranceType,
            'language': language,
            'liability-type': liabilityType,
            'loan-request-purpose': loanRequestPurpose,
            'loan-type': loanType,
            // 'nbb-reply-status': nbbReplyStatus,
            // 'nbb-type': nbbType,
            'ownership-type': ownershipType,
            'periodicity': periodicity,
            'phone-country-code': phoneCountryCode,
            'proof-audience': proofAudience,
            'proof-category': proofCategory,
            'proof-status': proofStatus,
            'realty-acquisition-type': realtyAcquisitionType,
            'realty-purpose': realtyPurpose,
            'realty-type': realtyType,
            'realty-usage-type': realtyUsageType,
            'regularity-type': regularityType,
            'relationship': relationship,
            'renovation-type': renovationType,
            'refinance-amount-type': refinanceAmountType,
            'build-type': buildType,
            'sector': sector,
            'statute': statute,
            'sex': sex,
            'signature-type': signatureType,
            'status': status,
            'user-role': userRole,
            'variability': variability,
            'task-status': taskStatus,
            'task-category': taskCategory,
            'task-type': taskType,
            'library-category': libraryCategory,
            'new-realty-option': newRealty,
            'news-type': newsType,
            'commission-file-status': commissionFileStatus,
            'recovery-status': recoveryStatus,
            'commission-statement-status': commissionStatementStatus,
            'commission-class': commissionClass,
            'fsma-status': fsmaStatus,
            'broker-type': brokerType,
            'own-funds-type': ownFundsType,
            'a-card-details': aCardDetails,
            'rental-type':rentalType
          };
          this.translateService.onLangChange.subscribe(() => {
            this.sortResources();
          });
          Object.keys(this.resources).forEach(key => {
            this.resources[key] = this.resources[key].map(option => this.generateTranslationKey(option, key));
          });
          this.sortResources();
        }
      )
    );
  }

  sortResources() {
    Object.keys(this.resources).forEach(key => {
      if (resourceOrder[key]) {
        this.sortResource(key);
      }
    });
  }

  sortResource(key: string) {
    this.resources[key].sort(sortByOrder(key, this.translateService));
  }

  public getResources() {
    return this.apiService.get(`/resources/?cache_version=${this.coreVersion}`);
  }

  public getResource(endPoint) {
    return this.apiService.get(`/resources/${endPoint}/?cache_version=${this.coreVersion}`);
  }

  public generateTranslationKey(option, resource) {
    option.key = `ç.resource.${resource}.${option.definition}`;
    return option;
  }

  public getResourceInstance(resource, key, value) {
    if (!this.resources) {
      return new Resource();
    }
    try {
      return this.resources[resource].find(object => {
        return object[key] === value;
      });
    } catch (TypeError) {
      return undefined;
    }
  }

  public getDefault(resource: string) {
    if (defaults[resource]) {
      return this.getResourceInstance(resource, 'definition', defaults[resource].definition);
    }
    return null;
  }

  public getResourceInstances(resource: string): Resource[] {
    return this.resources[resource];
  }
}

import {Injectable} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private apiService: ApiService) {
  }

  public getOffer(loanRequestId, body = {}) {
    return this.apiService.post(`/api/loan-requests/${loanRequestId}/offer/`, body);
  }

  public clearOffer(loanRequestId: number) {
    const body = {
      clearState: true
    };
    return this.apiService.post(`/api/loan-requests/${loanRequestId}/offer/`, body);
  }

  public getCurrentOffer(loanRequestId) {
    return this.apiService.get(`/api/loan-requests/${loanRequestId}/offer/`);
  }

  public getAllowedOffers(loanRequestId) {
    return this.apiService.get(`/api/loan-requests/${loanRequestId}/allowed-offers/`);
  }

  public getAllProductCombos() {
    return this.apiService.get(`/api/product-combos/`);
  }

  public getProductCombo(productComboId: number) {
    return this.apiService.get(`/api/product-combos/${productComboId}/`);
  }

  public getAllProducts() {
    return this.apiService.get(`/api/products/`);
  }

  public getProduct(productId) {
    return this.apiService.get(`/api/products/${productId}`);
  }

  public calculateAmortization(body = {}) {
    return this.apiService.post(`/api/calculate/credit/`, body);
  }

  public predictCosts(loanRequestId, body = {}) {
    return this.apiService.post(`/api/loan-requests/${loanRequestId}/offer/predict/`, body);
  }
}

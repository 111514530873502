export class Resource {
  id: number;
  definition: string;
  key: string;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.definition = data.definition || '';
      this.key = data.key || '';
    }
  }

  static createResource(data) {
    if (data) {
      return new Resource(data);
    } else {
      return null;
    }
  }
}

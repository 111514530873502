import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SnackBarService} from '../services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else if (!(error instanceof Array) && error.error && error.error.non_field_errors && !(error.error.non_field_errors instanceof Array)) {
          // server-side error
          errorMessage = `${error.error.non_field_errors}`;
        }

        if (errorMessage) {
          this.snackBarService.open(errorMessage, 'error');
        }
        return throwError(error);
      })
    );
  }
}

import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {ImageComponent} from './components/image/image.component';
import {MaterialModule} from '../material/material.module';
import {DialogComponent} from './components/dialog/dialog.component';
import {DynamicFormComponent} from './components/dynamic-form/dynamic-form.component';
import {DynamicFormQuestionComponent} from './components/dynamic-form-question/dynamic-form-question.component';
import {DynamicTableComponent} from './components/dynamic-table/dynamic-table.component';
import {CardComponent} from './components/card/card.component';
import {CardsComponent} from './components/cards/cards.component';
// import { NationalNumberMaskDirective } from './directives/national-number-mask.directive';
import {DateFormatPipe} from './pipe/date-format/date-format.pipe';
import {DayFormatPipe} from './pipe/day-format/day-format.pipe';
import {TimeFormatPipe} from './pipe/time-format/time-format.pipe';
import {LoadComponent} from './components/load/load.component';
import {DataCardSingleComponent} from './components/data-card-single/data-card-single.component';
import {DataCardGroupedComponent} from './components/data-card-grouped/data-card-grouped.component';
import {ErrorCardComponent} from './components/error-card/error-card.component';
import {MetricCardComponent} from './components/metric-card/metric-card.component';
import {BottomSheetComponent} from './components/bottom-sheet/bottom-sheet.component';
import {DocumentDialogComponent} from './components/document-dialog/document-dialog.component';
import {TimelineComponent} from './components/timeline/timeline.component';
import {RelativeDateFormatPipe} from './pipe/relative-date-format/relative-date-format.pipe';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {SubsectionComponent} from './components/subsection/subsection.component';
import {EnvironmentCurrencyPipe} from './pipe/environment-currency/environment-currency.pipe';
import {LabelComponent} from './components/label/label.component';
import {InfoCardComponent} from './components/info-card/info-card.component';
import {GoogleStreetviewComponent} from './components/google-streetview/google-streetview.component';
import {ContactDetailsDialogComponent} from './components/contact-details-dialog/contact-details-dialog.component';
import {SnackbarComponent} from './components/snackbar/snackbar.component';
import {ContextMenuComponent} from './components/context-menu/context-menu.component';
import {DatePickerComponent} from './components/datepicker/date-picker/date-picker.component';
import {MonthPickerComponent} from './components/datepicker/month-picker/month-picker.component';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';
import {DateTimeFormatPipe} from "./pipe/date-time-format/date-time-format.pipe";
import { OwnFundsDialogComponent } from './components/own-funds-dialog/own-funds-dialog.component';
import {YearPickerComponent} from "./components/datepicker/year-picker/year-picker.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MaterialModule, TranslateModule],
  declarations: [
    ImageComponent,
    DialogComponent,
    OwnFundsDialogComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ImageComponent,
    DynamicTableComponent,
    CardComponent,
    CardsComponent,
    // NationalNumberMaskDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    DayFormatPipe,
    TimeFormatPipe,
    LoadComponent,
    DataCardSingleComponent,
    DataCardGroupedComponent,
    ErrorCardComponent,
    MetricCardComponent,
    BottomSheetComponent,
    DocumentDialogComponent,
    TimelineComponent,
    RelativeDateFormatPipe,
    ConfirmationDialogComponent,
    SubsectionComponent,
    EnvironmentCurrencyPipe,
    LabelComponent,
    InfoCardComponent,
    GoogleStreetviewComponent,
    ContactDetailsDialogComponent,
    SnackbarComponent,
    ContextMenuComponent,
    DatePickerComponent,
    MonthPickerComponent,
    AddressAutocompleteComponent,
    YearPickerComponent,
  ],
  entryComponents: [
    DialogComponent,
    OwnFundsDialogComponent,
    BottomSheetComponent,
    DocumentDialogComponent,
    ConfirmationDialogComponent,
    ContactDetailsDialogComponent,
    SnackbarComponent,
    AddressAutocompleteComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ImageComponent,
    MaterialModule,
    DialogComponent,
    OwnFundsDialogComponent,
    DynamicFormComponent,
    DynamicTableComponent,
    CardComponent,
    // NationalNumberMaskDirective,
    CardsComponent,
    LoadComponent,
    TimeFormatPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    DayFormatPipe,
    DataCardSingleComponent,
    DataCardGroupedComponent,
    ErrorCardComponent,
    MetricCardComponent,
    BottomSheetComponent,
    TimelineComponent,
    SubsectionComponent,
    EnvironmentCurrencyPipe,
    LabelComponent,
    InfoCardComponent,
    GoogleStreetviewComponent,
    SnackbarComponent,
    ContextMenuComponent,
    MonthPickerComponent,
    AddressAutocompleteComponent,
    YearPickerComponent,
  ],
  providers: [CurrencyPipe],
})
export class SharedModule {
}

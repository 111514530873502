import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {ApiService} from '../../../core/services/api.service';
import {LocalStorageService} from '../../../core/services/local-storage.service';
import {SessionStorageService} from '../../../core/services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly apiService: ApiService,
    private readonly localStorageService: LocalStorageService,
    private readonly sessionStorageService: SessionStorageService
  ) {
  }

  public getUsers(queryparams = {}) {
    let params = new HttpParams();
    for (const key of Object.keys(queryparams)) {
      params = params.set(key, queryparams[key]);
    }
    return this.apiService.get(`/api/users/`, params);
  }

  public getSelf() {
    return this.apiService.get(`/api/me/`);
  }

  public updateSelf(body) {
    return this.apiService.patch(`/api/me/`, body);
  }

  public getUserLanguage(): string {
    const currentLanguage = this.localStorageService.get('language');
    if (currentLanguage) {
      return currentLanguage;
    }
    const tokenPayload = JSON.parse(this.sessionStorageService.get('tokenPayload'));
    const language = (tokenPayload && tokenPayload.language && tokenPayload.language.definition) || undefined;
    this.localStorageService.set('language', language);
    return language;
  }
}

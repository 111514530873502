import {Injectable} from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';

import {LocalStorageService} from './local-storage.service';

import {LocaleService} from './locale.service';

export const LANGUAGES = ['nl-BE', 'fr-BE', 'en-UK'];

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private readonly localeService: LocaleService,
    private readonly localStorageService: LocalStorageService,
    private readonly translateService: TranslateService
  ) {
    this.addLanguages();
    this.setLanguage();
  }

  changeLanguage(languageDefinition: string): void {
    this.localStorageService.set('locale', languageDefinition);
    this.setLanguage(languageDefinition);
  }

  public getCurrentLanguage(): string | null {
    return this.localStorageService.get('locale') || this.getDefaultLanguage();
  }

  public getDefaultLanguage(): string {
    return this.localeService.locale;
  }

  public setLanguage(language?: string): void {
    let lang: string;
    if (this.localStorageService.get('locale')) {
      lang = this.localStorageService.get('locale');
    } else if (typeof language !== 'undefined') {
      lang = language;
    } else {
      switch (this.localeService.region) {
        case 'BE':
          lang = 'nl-BE';
          break;
        default:
          lang = this.getDefaultLanguage();
          break;
      }
    }
    this.translateService.use(lang);
    this.translateService.onLangChange.emit(<LangChangeEvent>{});
  }

  public getLanguages(): string[] {
    return this.translateService.getLangs();
  }

  private addLanguages(): void {
    this.translateService.addLangs(LANGUAGES);
  }
}

import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadService} from '../services/load.service';

@Injectable({
  providedIn: 'root',
})
export class LoadInterceptor implements HttpInterceptor {
  constructor(private loadService: LoadService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If loading is explicitly false
    /**
     * THIS IS A VERY LARGE COMMENT TO REMIND THAT THIS SHOULD BE CHANGED
     * LOADING = FALSE IS REALLY BAD DESIGN
     * @NICK, SOLVE IT
     */
    const loadingParam = String(request.params.get('loading'));
    if (loadingParam !== null && loadingParam === 'false') {
      return next.handle(request);
    }
    this.loadService.startLoading();
    return next.handle(request).pipe(
      finalize(() => {
        this.loadService.stopLoading();
      })
    );
  }
}

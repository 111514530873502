import {Injectable} from '@angular/core';
import {UtilService} from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class CaseStyleService {
  constructor(public readonly utilService: UtilService) {
  }

  public toSnakeCase(camelCaseString) {
    const capitalLetters = camelCaseString.match(/([A-Z])/g);
    if (!capitalLetters) {
      return camelCaseString;
    }
    let snakeCaseString = camelCaseString.toString();
    for (let i = 0, n = capitalLetters.length; i < n; i++) {
      snakeCaseString = snakeCaseString.replace(new RegExp(capitalLetters[i]), '_' + capitalLetters[i].toLowerCase());
    }
    if (snakeCaseString.slice(0, 1) === '_') {
      snakeCaseString = snakeCaseString.slice(1);
    }
    return snakeCaseString;
  }

  public convertKeysToSnakeCase(camelCase) {
    if (this.utilService.isObject(camelCase)) {
      const snakeCase = {};

      Object.keys(camelCase).forEach(key => {
        snakeCase[this.toSnakeCase(key)] = this.convertKeysToSnakeCase(camelCase[key]);
      });

      return snakeCase;
    } else if (this.utilService.isArray(camelCase)) {
      return camelCase.map(i => {
        return this.convertKeysToSnakeCase(i);
      });
    }
    return camelCase;
  }

  public toCamelCase(snakeCaseString) {
    return snakeCaseString.replace(/(_\w)/g, function (m) {
      return m[1].toUpperCase();
    });
  }

  public convertKeysToCamelCase(snakeCase) {
    if (this.utilService.isObject(snakeCase)) {
      const camelCase = {};

      Object.keys(snakeCase).forEach(key => {
        camelCase[this.toCamelCase(key)] = this.convertKeysToCamelCase(snakeCase[key]);
      });

      return camelCase;
    } else if (this.utilService.isArray(snakeCase)) {
      return snakeCase.map(i => {
        return this.convertKeysToCamelCase(i);
      });
    }
    return snakeCase;
  }
}

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {map, mergeMap, tap, toArray} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {SessionStorageService} from './session-storage.service';
import {Permission} from '../models/permission.model';
import {CaseStyleService} from '../../shared/services/case-style/case-style.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    private apiService: ApiService,
    private caseStyleService: CaseStyleService,
    private sessionStorageService: SessionStorageService
  ) {
    // On load, get them from session storage, so the user can actually reload the page.
    const storedPermissions = this.sessionStorageService.get('permissions');
    if (storedPermissions) {
      this.permissions = JSON.parse(storedPermissions);
    }
  }

  // RESTRUCTURE
  // authorized = [
  // 	'documentsInviteClients', // ==> lcommuncationmodule.invite_clients
  // 	'documentsDeleteProofRequirement', // ==> proofmodule.delete_documentproofobject
  // 	'navigateToDocuments', // ==> loanrequestmodule.navigateToDocuments
  // 	'navigateToOverview', // ==> loanrequestmodule.navigateToOverview
  // 	'navigateToLoanRequest', // ==> loanrequestmodule.navigateToLoanRequest
  // 	'navigateToLogin', // ==> loanrequestmodule.navigateToLogin
  // 	'documentsSendReminder', // ==> communicationmodule.send_reminder
  // ];

  // roles = {
  // 	upload: [],
  // 	broker: [...this.authorized, 'summaryRemarksSection'], ==> loanrequestmodule.view_loanrequestcomment
  // 	analyst: [
  // 		...this.authorized,
  // 		'summaryControlPanel',
  // 		'summaryMetricsSection',
  // 		'existingCreditsNbbCheck', ==> nbb.view_consult or something
  // 		'summaryAddComment', ==>
  // 		'documentsAcceptOrDeclineProofRequirement' ==> proofmodule.analyse_object
  // 	],
  // };

  public permissions: string[] = [];

  hasPermission(permission) {
    // CATCH IF CURRENT ROLE DOES NOT MATCH KEY IN ROLES OBJECT...
    if (this.permissions.includes(permission)) {
      return true;
    }
    return false;
  }

  setPermissions(): Observable<any> {
    this.permissions = [];
    this.sessionStorageService.unset('permissions');
    return this.apiService.get('/api/me/permissions/').pipe(
      mergeMap(permissions => from(permissions)),
      map(permission => new Permission(permission)),
      map(permission => this.caseStyleService.toCamelCase(`${permission.appLabel}.${permission.codename}`)),
      tap(permission => this.permissions.push(permission)),
      toArray(),
      tap(() => this.sessionStorageService.set('permissions', JSON.stringify(this.permissions)))
    );
  }

  clearPermissions() {
    this.sessionStorageService.unset('permissions');
  }
}

import { Notary } from "../../notary/models/notary.model";

class Address {
  street: string;
  houseNumber: string;
  box: string;
  zipCode: string;
  city: string;
  country: string;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.street = data.street || null;
      this.houseNumber = data.houseNumber || null;
      this.box = data.box || null;
      this.zipCode = data.zipCode || null;
      this.city = data.city || null;
      this.country = data.country || null;
    }
  }
}

export class ThirdParty {
  id: number;
  name: string;
  address: Address;
  vatNumber: string;
  brokerTypeTitle: string;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.name = data.name || '';
      this.address = new Address(data.address);
      this.vatNumber = data.vatNumber || null;
    }
  }

  static createThirdParty(data) {
    if (data && data.id) {
      return new ThirdParty(data);
    }
    return null;
  }
}

export class OtherInfo {
  notary: Notary;
  coBroker: ThirdParty;
  repaymentBankAccountNumber: string;
  brokerId: number;
  minDstiOfGuarantor: number;
  minSurplusOfGuarantor: number;

  constructor() {
    this.notary = null;
    this.coBroker = null;
    this.repaymentBankAccountNumber = '';
    this.brokerId = null;
    this.minDstiOfGuarantor = null;
    this.minSurplusOfGuarantor = null;
  }
}

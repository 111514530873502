import {Injectable, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, BehaviorSubject, throwError} from 'rxjs';
import {catchError, finalize, filter, switchMap, take} from 'rxjs/operators';

import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshInProgress = false;
  private refreshTokenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private inj: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        // Don't intercept for jwt requests
        if (request.url.includes('jwt')) {
          // If this already was a refresh, then we log out.
          if (request.url.includes('refresh')) {
            this.inj.get(AuthService).logout();
          }
          // Throw the error normally
          return throwError(error);
        }

        // If it's not a 401, we just return the error
        if (!error.status || error.status !== 401) {
          return throwError(error);
        }

        if (this.refreshInProgress) {
          return this.refreshTokenSubject$.pipe(
            filter(result => result != null),
            take(1),
            switchMap(() => {
              return next.handle(this.addRefreshedToken(request));
            })
          );
        } else {
          // Set that the refresh is in progress
          this.refreshInProgress = true;
          this.refreshTokenSubject$.next(null);

          // Refresh the token
          return this.inj
            .get(AuthService)
            .refreshToken()
            .pipe(
              switchMap(() => {
                this.refreshTokenSubject$.next(this.inj.get(AuthService).getAccessToken());
                return next.handle(this.addRefreshedToken(request));
              }),
              finalize(() => {
                this.refreshInProgress = false;
              })
            );
        }
      })
    );
  }

  addRefreshedToken(request: HttpRequest<any>) {
    const token = this.inj.get(AuthService).getAccessToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Token ${token}`,
        },
      });
    }
    return request;
  }
}

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LocalGuard} from './core/guards/local.guard';
import {RoleGuard} from './core/guards/role.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    data: {
      expectedRoles: [],
    },
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [RoleGuard],
    data: {
      expectedRoles: [
        /*'upload',*/ 'broker',
        'analyst',
        'decider',
        'headDecider',
        'operationsManager',
        'seniorDecider',
        'accountManager',
        'brokerManager',
        'fileManager'
      ],
    },
  },
  {
    path: 'sandbox',
    loadChildren: () => import('./dev/dev.module').then(m => m.DevModule),
    canActivate: [LocalGuard],
    data: {
      expectedRoles: [],
    },
  },
  {
    path: '**',
    redirectTo: '/dashboard/overview',
    pathMatch: 'full',
    data: {
      expectedRoles: [
        /*'upload',*/ 'broker',
        'analyst',
        'decider',
        'headDecider',
        'operationsManager',
        'seniorDecider',
        'accountManager',
        'brokerManager',
        'fileManager'
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}

import {Client} from '../../client/models/client.model';
import {Resource} from '../../../shared/models/resource/resource.model';
import {ThirdParty} from '../../third-party/models/third-party.model';
import {Document} from '../../documents/models/document.model';
import {User} from '../../user/models/user.model';
import { Notary } from '../../notary/models/notary.model';

export class OtherCreditProviderRequest {
  creditProvider: Resource;
  amount: number;
  applicationDate: Date;
  offerReceived: boolean;

  constructor(data?: any) {
    if (data === undefined || data === null) {
      return;
    }
    this.creditProvider = new Resource(data.creditProvider);
    this.amount = data.amount || 0;
    this.applicationDate = data.applicationDate || null;
    this.offerReceived = data.offerReceived || false; // Can't be null so defaulting is ok
  }
}

export class LoanRequest {
  id: number;
  status: Resource;
  internalReference: string;
  borrowerReference: string;
  created: string;
  commission: number;
  lastModified: string;
  costPurchase: number;
  costRenovation: number;
  costRefinance: number;
  costBuilding: number;
  costBuyout: number;
  costCreditTakeover: number;
  costInsurance: number;
  costHandling: number;
  costFile: number;
  costAdditionalFinancingNeed: number;
  costDistributionNotary: number;
  costDistributionRegistration: number;
  costCreditNotary: number;
  costCreditRegistration: number;
  costPurchaseNotary: number;
  costPurchaseRegistration: number;
  costEstimation: number;
  liquidities: number;
  loanAmount: number;
  amountAtDeed: number;
  partialAmount: number;
  ownFunds: number;
  businessCapital: number;
  deedDate: string;
  dateFundsRequested: string;
  dateFundsProvisioned: string;
  dateOfferMade: string;
  dateInitialAgreement: string;
  datePricingSheetVersion: string;
  dateSubmission: string;
  actionRequired: boolean;
  dateContractAllowed: string;
  dateCompletion: string;
  filedOtherProvider: boolean;
  hasRecordCredits: boolean;
  analyst: User; // TODO Nick
  fileManager: User; // TODO Nick
  broker: ThirdParty;
  coBroker: ThirdParty;
  mainBroker: ThirdParty;
  relatedBroker: ThirdParty;
  createdBy: User;
  notary: Notary;
  needsSeniorDecision: boolean;
  personalMortgageLoan: boolean;
  needsSeniorDecisionOverwrite?: boolean;
  repaymentBankAccountNumber: string;
  statusId: number;
  source: Resource;
  companyCarIncome: number;
  zipFile?: {
    url: string;
    mimeType: string;
    documentType: Resource;
  };
  purposes: Array<Resource>;
  prospectus: Document;
  otherCreditProviderRequests: Array<OtherCreditProviderRequest>;
  recordBankReferences: {
    reference: string;
    productItem: {
      id: number;
      internalReference: string;
    };
  }[];
  motivationForRefinancing: string;
  motivationForLiquidites: string;
  minDstiOfGuarantor: number;
  minSurplusOfGuarantor: number;
  dscr: number;


  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.status = new Resource(data.status);
      this.internalReference = data.internalReference || null;
      this.borrowerReference = data.borrowerReference || '';
      this.created = data.created || null;
      this.commission = data.commission || 0;
      this.lastModified = data.lastModified || null;
      this.costPurchase = data.costPurchase || 0;
      this.costRenovation = data.costRenovation || 0;
      this.costRefinance = data.costRefinance || 0;
      this.costBuilding = data.costBuilding || 0;
      this.costBuyout = data.costBuyout || 0;
      this.costCreditTakeover = data.costCreditTakeover || 0;
      this.costDistributionNotary = data.costDistributionNotary || 0;
      this.costDistributionRegistration = data.costDistributionRegistration || 0;
      this.costCreditNotary = data.costCreditNotary || 0;
      this.costCreditRegistration = data.costCreditRegistration;
      this.costPurchaseNotary = data.costPurchaseNotary || 0;
      this.costPurchaseRegistration = data.costPurchaseRegistration || 0;
      this.costFile = data.costFile || 0;
      this.costHandling = data.costHandling || 0;
      this.costEstimation = data.costEstimation || 0;
      this.costInsurance = data.costInsurance || 0;
      this.costAdditionalFinancingNeed = data.costAdditionalFinancingNeed || 0;
      this.liquidities = data.liquidities || 0;
      this.loanAmount = data.loanAmount || 0;
      this.ownFunds = data.ownFunds || 0;
      this.businessCapital = data.businessCapital || 0;
      this.deedDate = data.deedDate || null;
      this.dateFundsRequested = data.dateFundsRequested || null;
      this.dateFundsProvisioned = data.dateFundsProvisioned || null;
      this.dateOfferMade = data.dateOfferMade || null;
      this.dateInitialAgreement = data.dateInitialAgreement || null;
      this.datePricingSheetVersion = data.datePricingSheetVersion || null;
      this.dateSubmission = data.dateSubmission || null;
      this.dateCompletion = data.dateCompletion || null;
      this.actionRequired = data.actionRequired === undefined ? false : data.actionRequired;
      this.dateContractAllowed = data.dateContractAllowed || null;
      this.filedOtherProvider = data.filedOtherProvider !== undefined ? data.filedOtherProvider : false;
      this.analyst = data.analyst || null; // TODO Nick
      this.fileManager = data.fileManager || null; // TODO Nick
      this.broker = new ThirdParty(data.broker);
      this.coBroker = new ThirdParty(data.coBroker);
      this.notary = new Notary(data.notary);
      this.needsSeniorDecision = data.needsSeniorDecision === undefined ? false : data.needsSeniorDecision;
      this.needsSeniorDecisionOverwrite = data.needsSeniorDecisionOverwrite === undefined ? null : data.needsSeniorDecision;
      this.repaymentBankAccountNumber = data.repaymentBankAccountNumber;
      this.loanAmount = data.loanAmount || 0;
      this.amountAtDeed = data.amountAtDeed || 0;
      this.partialAmount = data.partialAmount || 0;
      this.purposes = [];
      this.statusId = data.statusId || null;
      this.source = data.source ? new Resource(data.source) : null;
      this.companyCarIncome = data.companyCarIncome || null;
      this.personalMortgageLoan = data.personalMortgageLoan === false ? false : true;
      this.prospectus = new Document(data.prospectus);
      if (!(data.purposes === undefined)) {
        data.purposes.forEach((purpose: any) => {
          this.purposes.push(new Resource(purpose));
        });
      }
      this.otherCreditProviderRequests = [];
      if (!(data.otherCreditProviderRequests === undefined)) {
        data.otherCreditProviderRequests.forEach((otherRequest: any) => {
          this.otherCreditProviderRequests.push(new OtherCreditProviderRequest(otherRequest));
        });
      }
      this.motivationForRefinancing = data.motivationForRefinancing;
      this.motivationForLiquidites = data.motivationForLiquidites;
      this.minDstiOfGuarantor = data.minDstiOfGuarantor;
      this.minSurplusOfGuarantor = data.minSurplusOfGuarantor;
      this.dscr = data.dscr;
    }
  }
}

export class LoanRequestClientSlim {
  id: number;
  loanRequest: LoanRequest;
  client: Client;
  role: Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.id = data.id || null;
      this.loanRequest = new LoanRequest(data.loanRequest);
      this.client = new Client(data.client);
      this.role = new Resource(data.role);
    }
  }
}

export class LoanRequestClient {
  id: number;
  profile: {
    id: number;
    firstName: string;
    lastName: string;
    nationalNumber: string;
    idCardNumber: string;
    createdBy: {
      id: number;
      email: string;
    };
  };
  loanRequest: {
    id: number;
    internalReference: string;
  };
  client: {
    id: number;
  };
  role: Resource;

  constructor(options: { client?: any; role?: any } = {}) {
    this.client = options.client || {};
    this.role = new Resource(options.role);
  }
}

export class OwnFunds {
  ownFundsType: Resource;
  id: number;
  amount: number;

  constructor(data?) {
    if (!(data === undefined || data === null)) {
      this.ownFundsType = new Resource(data.ownFundsType);
      this.id = data.id || null;
      this.amount = data.amount || 0;
    }
  }
}

import {ValidatorFn, AsyncValidatorFn} from '@angular/forms';

export class InputBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  disabled: boolean;
  order: number;
  controlType: string;
  visible: boolean;
  class: string;
  validators?: ValidatorFn[];
  asyncValidator?: AsyncValidatorFn;
  multiple?: boolean;
  prefix?: string;
  suffix?: string;
  specialDisabledStyle = false;

  constructor(
    data: {
      value?: any;
      key?: string;
      label?: string;
      required?: boolean;
      disabled?: boolean;
      order?: number;
      controlType?: string;
      visible?: boolean;
      class?: string;
      validators?: ValidatorFn[];
      asyncValidator?: AsyncValidatorFn;
      multiple?: boolean;
      prefix?: string;
      suffix?: string;
      specialDisabledStyle?: boolean;
    } = {}
  ) {
    this.value = data.value;
    this.key = data.key || '';
    this.label = data.label || '';
    this.required = /* !!options.required */ data.required === undefined ? true : data.required;
    this.disabled = /* !!options.disabled */ data.disabled === undefined ? false : data.disabled;
    this.order = data.order === undefined ? 1 : data.order;
    this.controlType = data.controlType || '';
    this.visible = data.visible === undefined ? true : data.visible;
    this.class = data.class === undefined ? 'span12' : data.class;
    this.validators = data.validators === undefined ? [] : data.validators;
    this.asyncValidator = data.asyncValidator;
    this.multiple = data.multiple === undefined ? false : data.multiple;
    this.prefix = data.prefix === undefined ? '' : data.prefix;
    this.suffix = data.suffix === undefined ? '' : data.suffix;
    this.specialDisabledStyle = data.specialDisabledStyle || false;
  }
}

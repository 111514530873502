<div>
  <form [formGroup]="form">
    <div class="containerFormField">
      <ng-container *ngIf="formConfiguration">
        <div *ngFor="let question of formConfiguration.formControl.questions" class="formField {{ question.class }}">
          <app-dynamic-form-question
            *ngIf="question.visible"
            [form]="form"
            [question]="question"
            (valueChange)="valueChanged()"
            (buttonClicked)="onClick()"
            class="{{ question.key }}"
            [markAsTouched]="triggerMarkAsTouched"
          ></app-dynamic-form-question>
        </div>
      </ng-container>
    </div>
    <!-- <div class='containerErrorList'>
      <ul>
        <li *ngFor="let error of getErrors()">
          {{ error }}
        </li>
      </ul>
    </div> -->
    <button type="submit" [disabled]="!form.valid" hidden>{{ 'ç.misc.action.save' | translate }}</button>
  </form>
  <!--  <div *ngIf='payLoad'>
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div> -->
</div>

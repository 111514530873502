import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, forkJoin, Observable, Subject, throwError} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import { NewsPagination } from '../../features/newsflash/models/newsflash-statement.model';
import { NewsflashService } from '../../features/newsflash/services/newsflash.service';

import {UserService} from '../../features/user/services/user.service';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import {ApiService} from './api.service';
import {JwtService} from './jwt.service';
import {LanguageService} from './language.service';
import {LocalStorageService} from './local-storage.service';
import {PermissionService} from './permission.service';
import {SessionStorageService} from './session-storage.service';
import {SnackBarService} from './snackbar.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  public token$: Subject<any>;
  targetRoute: { url: string; queryParams: any };

  constructor(
    private readonly apiService: ApiService,
    private readonly localStorageService: LocalStorageService,
    private readonly permissionsService: PermissionService,
    private readonly router: Router,
    private readonly sessionStorageService: SessionStorageService,
    private readonly snackbarService: SnackBarService,
    private readonly jwtService: JwtService,
    private readonly languageService: LanguageService,
    private readonly userService: UserService,
    private readonly newsflashService: NewsflashService,
    private readonly dialogService: DialogService
  ) {
    this.token$ = new Subject<any>();
    this.token$.subscribe(tokens => {
      this.setUser(tokens);
      this.setTokens(tokens);
    });
  }

  public setUser(tokens) {
    const unpackedToken = this.jwtService.unpackToken(tokens.access);
    this.sessionStorageService.set('tokenPayload', JSON.stringify(this.jwtService.unpackToken(tokens.access)));
    this.sessionStorageService.set('userName', `${unpackedToken.firstName} ${unpackedToken.lastName}`);
    this.sessionStorageService.set('userRole', this.jwtService.unpackToken(tokens.access).role.definition);
    if (this.languageService.getCurrentLanguage()) {
      this.userService.updateSelf({language: {definition: this.languageService.getCurrentLanguage()}}).subscribe();
    } else {
      this.languageService.setLanguage(unpackedToken.language.definition);
    }
  }

  public getUserId() {
    return this.jwtService.unpackToken(this.getAccessToken()).userId;
  }

  public setTokens(tokens) {
    this.localStorageService.set('accessToken', tokens.access);
    // For refreshes, we don't get a new refresh token.
    if (tokens.refresh) {
      this.localStorageService.set('refreshToken', tokens.refresh);
    }
  }

  public refreshToken(): Observable<any> {
    const token = this.getRefreshToken();
    if (!token) {
      return EMPTY;
    }
    const body = {
      refresh: token,
    };
    return this.apiService.post('/api/jwt/refresh/', body).pipe(
      map(response => {
        this.token$.next(response);
      }),
      catchError(error => {
        this.logout();
        return throwError(error);
      }),
      mergeMap(() => {
        return this.permissionsService.setPermissions();
      })
    );
  }

  public getAccessToken() {
    return this.localStorageService.get('accessToken');
  }

  public getRefreshToken() {
    return this.localStorageService.get('refreshToken');
  }

  public unsetTokens() {
    this.localStorageService.unset('accessToken');
    this.localStorageService.unset('refreshToken');
  }

  public isAuthenticated() {
    return this.getAccessToken() !== null;
  }

  public login(username: string, password: string): void {
    const body = {
      username,
      password,
    };
    this.apiService
      .post('/api/jwt/', body)
      .pipe(
        tap(response => {
          const tempToken$ = this.token$.subscribe(() => {
            this.targetRoute
              ? this.router.navigate([this.targetRoute.url.split('?')[0]], {queryParams: this.targetRoute.queryParams})
              : this.router.navigate(['/dashboard/overview']);
            this.targetRoute = {url: '', queryParams: {}};
            tempToken$.unsubscribe();
          });
          this.token$.next(response);
        })
        // mergeMap(() => {
        // 	return this.permissionsService.setPermissions();
        // })
      )
      .subscribe(
        () => {
          forkJoin([
            this.newsflashService.getNews(new NewsPagination({
            page: 1,
            pageSize: 1, 
            confirmed: false,
          })), 
          this.userService.getSelf()
          ]).subscribe(news => {
            if (news[0].count !== 0) {
              const id = news[0].results[0].id;
              forkJoin([this.newsflashService.getNew(id),this.newsflashService.getFile(id)]).subscribe((result) => {
                let data = result[0];
				        const lang = news[1].language.definition === 'fr-BE' ? 'fr' : 'nl';
                const files = result[1].filter(fil => fil.lang === lang);
                if (result) {
                  data = {
                    ...data,
                    language: news[1].language.definition,
                    files: files,
                  }
                  this.dialogService.openNewsflash(data);
                }
              });
            }
          })
        },
        error => {
          if (error.status && error.status === 401) {
            this.snackbarService.open('ç.userFeedback.login.error', 'error');
          } else {
            this.snackbarService.open('ç.userFeedback.service.error', 'error');
            throwError(error);
          }
        }
      );
  }

  public logout(): void {
    this.unsetTokens();
    this.permissionsService.clearPermissions();
    this.sessionStorageService.unset('userName');
    this.sessionStorageService.unset('userRole');
    this.sessionStorageService.unset('tokenPayload');
    this.router.navigate(['/auth/login']);
  }

  public forgotPassword(email: string): Observable<any> {
    const body = {
      email: email,
    };
    return this.apiService.post('/api/forgot-password/', body);
  }

  public resetPassword(newPassword: string, confirmPassword: string, token: string): Observable<any> {
    const body = {
      newPasswordOne: newPassword,
      newPasswordTwo: confirmPassword,
      token: token,
    };
    return this.apiService.post('/api/reset-password/', body);
  }

  public checkResetPasswordToken(token: string) {
    const body = {
      token: token,
    };
    return this.apiService.post('/api/check-reset-password/', body);
  }
}

import {Component, EventEmitter, Inject, OnInit, Output, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { PermissionService } from 'apps/brokerage-v1/src/app/core/services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {SnackBarService} from '../../../../../core/services/snackbar.service';
import {DialogData} from '../../../../../shared/models/dialog/dialog.model';
import {FormConfiguration} from '../../../../../shared/models/dynamic-form/dynamic-form.model';
import {FiscalAttestation, Liability} from '../../models/liability.model';
import {LiabilityService} from '../../services/liability.service';
import { ResourceService } from 'apps/brokerage-v1/src/app/shared/services/resource/resource.service';

@Component({
  selector: 'app-client-liability',
  templateUrl: './liability.component.html',
  styleUrls: ['./liability.component.scss']
})
export class LiabilityComponent implements OnInit, OnDestroy {
  @Output() liabilityBtnClicked = new EventEmitter<any>();
  @Output() fiscalBtbClicked = new EventEmitter<any>();
  liabilityFormConfig: FormConfiguration;
  fiscalFormConfig: FormConfiguration;
  liability: Liability;
  fiscal: FiscalAttestation;
  triggerMarkAllAsTouched = false;
  isDisabled: boolean;
  liabilityForm: FormGroup;
  fiscalForm: FormGroup;
  liabilityFormConfigurationErrors: Array<string> = [];
  liabilityFormConfigurationWarnings: Array<string> = [];
  fiscalFormConfigurationErrors: Array<string> = [];
  fiscalFormConfigurationWarnings: Array<string> = [];
  immovableAmountFormConfigurationErrors: Array<string> = [];
  immovableAmountFormConfigurationWarnings: Array<string> = [];
  immovableAmountFormConfig: FormConfiguration;
  immovableAmountForm: FormGroup;
	private readonly _destroy$ = new Subject<void>();


  constructor(
    private liabilityService: LiabilityService, 
    private snackbarService: SnackBarService, 
    public dialogRef: MatDialogRef<LiabilityComponent>, 
    public permissionService: PermissionService,
    private resourceService: ResourceService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }

  ngOnInit(): void {
    this.fillForm();
  }

  public fillForm(): void {
    this.liabilityFormConfig = this.dialogData.liabilityFormConfig;
    this.fiscalFormConfig = this.dialogData.fiscalFormConfig;
    this.immovableAmountFormConfig = this.dialogData.immovableAmountFormConfig;
    this.liability = this.dialogData.liability;
    this.fiscal = this.dialogData.fiscal;
    this.fiscal.fiscalAttestationAmount = this.liability.fiscalAttestationAmount;
    this.fiscal.fiscalAttestationFactor = this.liability.fiscalAttestationFactor;
    this.fiscal.immovableAmount = this.liability.immovableAmount;
    this.liabilityFormConfig.formControl.questions = this.liabilityService.getQuestions(this.liability);
    this.fiscalFormConfig.formControl.questions = this.liabilityService.getFiscalQuestions(this.fiscal);
    this.immovableAmountFormConfig.formControl.questions = this.liabilityService.getImmovableAmountQuestions(this.fiscal, this.liability);
    const data = new DialogData();
    data.init(this.dialogData);
    this.dialogData.buttons = data.buttons;
    if (this.liabilityFormConfig === undefined || this.liabilityFormConfig === null) {
      this.isDisabled = false;
      this.liabilityForm = new FormGroup({});
    }
    if (this.fiscalFormConfig === undefined || this.fiscalFormConfig === null) {
      this.isDisabled = false;
      this.fiscalForm = new FormGroup({});
    }
    if (this.immovableAmountFormConfig === undefined || this.immovableAmountFormConfig === null) {
      this.isDisabled = false;
      this.immovableAmountForm = new FormGroup({});
    }
  }

  public getLiabilityFormConfigErrors(errors): void {
    this.liabilityFormConfigurationErrors = errors
  }

  public getLiabilityFormConfigWarnings(warnings) {
    this.liabilityFormConfigurationWarnings = warnings;
  }

  public liabilityFormConfigValueChaned($event): void {    
    this.liabilityForm = $event;
    this.isDisabled = !this.liabilityForm.valid;
  }

  ngOnDestroy(): void {
		this._destroy$.next(undefined);
		this._destroy$.complete();
	} 
  
  private setFiscalAttestationAmount(earlyRepaymentFee, balance){
    return (Number(earlyRepaymentFee) + Number(balance)).toFixed(2);
  }

  public liabilityFormConfigValueInit($event): void {
    this.liabilityForm = $event;
    this.isDisabled = !this.liabilityForm.valid;
    this.calculateFiscalValues();
  }

  calculateFiscalValues(){
    this.liabilityForm.controls['earlyRepaymentFee'].valueChanges.pipe(takeUntil(this._destroy$))
    .subscribe((earlyRepaymentFee) => {
      this.fiscalForm.patchValue({
        fiscalAttestationAmount: this.setFiscalAttestationAmount(earlyRepaymentFee, this.liabilityForm.get('balance').value)
      });
    })
    this.liabilityForm.controls['balance'].valueChanges.pipe(takeUntil(this._destroy$))
    .subscribe((balance) => {
      this.fiscalForm.patchValue({
        fiscalAttestationAmount: this.setFiscalAttestationAmount(this.liabilityForm.get('earlyRepaymentFee').value, balance)
      });
      const fiscalAttestationAmount = this.setFiscalAttestationAmount(this.liabilityForm.get('earlyRepaymentFee').value, this.liabilityForm.get('balance').value);
      const percentageImmovable = this.immovableAmountForm.get('percentageImmovable').value;
      this.immovableAmountForm.patchValue({
        immovableAmount: Number(Number(fiscalAttestationAmount) * Number(percentageImmovable) / 100).toFixed(2)
      });
    })
  }

  public getImmovableAmountFormConfigrrors(errors): void {
    this.immovableAmountFormConfigurationErrors = errors
  }

  public getImmovableAmountFormConfigWarnings(warnings) {
    this.immovableAmountFormConfigurationWarnings = warnings;
  }

  public immovableAmountFormConfigValueChaned($event): void {
    this.immovableAmountForm = $event;
    this.isDisabled = !this.immovableAmountForm.valid;
  }

  public immovableAmountFormConfigValueInit($event): void {
    this.immovableAmountForm = $event;
    this.isDisabled = !this.immovableAmountForm.valid;
    this.caculateImmovableAmount();
  }

  caculateImmovableAmount(){
    this.immovableAmountForm.controls['percentageImmovable'].valueChanges.pipe(takeUntil(this._destroy$))
    .subscribe((percentageImmovable) => {
      const fiscalAttestationAmount = this.setFiscalAttestationAmount(this.liabilityForm.get('earlyRepaymentFee').value, this.liabilityForm.get('balance').value)
      this.immovableAmountForm.patchValue({
        immovableAmount: Number(Number(fiscalAttestationAmount) * percentageImmovable / 100).toFixed(2)
      });
    })
  }

  public getFiscalFormConfigrrors(errors): void {
    this.fiscalFormConfigurationErrors = errors
  }

  public getFiscalFormConfigWarnings(warnings) {
    this.fiscalFormConfigurationWarnings = warnings;
  }

  public fiscalFormConfigValueChaned($event): void {
    this.fiscalForm = $event;
    this.isDisabled = !this.fiscalForm.valid;
  }

  public fiscalFormConfigValueInit($event): void {
    this.fiscalForm = $event;
    this.isDisabled = !this.fiscalForm.valid;
  }

  onSubmit(): void {
    this.fiscalForm.value.immovableAmount = this.immovableAmountForm.value.immovableAmount
    this.dialogRef.close({liability: this.liabilityForm, fiscal: this.fiscalForm.value});
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  public liabilityButtonClicked(): void {
    this.liabilityForm.value.fiscalAttestationAmount = this.fiscalForm.value.fiscalAttestationAmount;
    this.liabilityForm.value.fiscalAttestationFactor = this.fiscalForm.value.fiscalAttestationFactor;
    this.liabilityBtnClicked.emit(this.liabilityForm);
  }

  
}

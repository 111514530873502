import {Income} from '../income/models/income.model';
import {Liability} from '../liability/models/liability.model';
import {Resource} from '../../../shared/models/resource/resource.model';

export class Email {
  value: string;
  emailType: Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.value = data.value || '';
      this.emailType = new Resource(data.emailType);
    }
  }
}

export class PhoneNumber {
  value: string;
  countryCode: Resource;
  phoneType: Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.value = data.value || '';
      this.countryCode = Resource.createResource(data.countryCode);
      this.phoneType = Resource.createResource(data.phoneType);
    }
  }
}

export class Address {
  street: string;
  houseNumber: string;
  box: string;
  zipCode: string;
  city: string;
  country: Resource;
  addressType: Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.street = data.street || '';
      this.houseNumber = data.houseNumber || '';
      this.box = data.box || '';
      this.zipCode = data.zipCode || '';
      this.city = data.city || '';
      this.country = Resource.createResource(data.country);
      this.addressType = Resource.createResource(data.addressType);
    }
  }
}

export class Relationship {
  id: number;
  clientFrom: { id: number };
  clientTo: { id: number };
  relationship: Resource;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.id = data.id;
      this.clientFrom = data.clientFrom;
      this.clientTo = data.clientTo;
      this.relationship = Resource.createResource(data.relationship);
    }
  }
}

export class ExistingCreditFromCentralBank {
  amount: number;
  balance: number;
  duration: number;
  fullMatch: boolean;
  guaranteed: boolean;
  initialAmount: number;
  interestRate: number;
  liabilityType: Resource;
  monthlyAmount: number;
  periodicity: Resource;
  regularisationDate: string;
  regularity: Resource;
  startDate: string;
  handlingCosts: number;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.amount = data.amount || 0;
      this.balance = data.balance || 0;
      this.duration = data.duration || 0;
      this.fullMatch = data.fullMatch === undefined ? false : data.fullMatch;
      this.guaranteed = data.guaranteed === undefined ? false : data.guaranteed;
      this.initialAmount = data.initialAmount || 0;
      this.interestRate = data.interestRate || 0;
      this.liabilityType = new Resource(data.liabilityType);
      this.monthlyAmount = data.monthlyAmount || 0;
      this.periodicity = new Resource(data.periodicity);
      this.regularisationDate = data.regularisationDate || '';
      this.regularity = new Resource(data.regularity);
      this.startDate = data.startDate || '';
      this.handlingCosts = data.handlingCosts || 0;
    }
  }
}

export class BaseClient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.id = data.id;
      this.firstName = data.firstName || '';
      this.middleName = data.middleName || '';
      this.lastName = data.lastName || '';
    }
  }
}

export class Client {
  id: number;
  civilStatus: Resource;
  sex: Resource;
  emails: Array<Email>;
  phoneNumbers: Array<PhoneNumber>;
  addresses: Array<Address>;
  firstName: string;
  middleName: string;
  lastName: string;
  nationality: Resource;
  birthDate: string;
  birthCity: string;
  nationalNumber: string;
  idIssuanceDate: string;
  idExpirationDate: string;
  idCardNumber: string;
  complianceTerror: boolean;
  complianceBank: boolean;
  complianceFraud: boolean;
  language: string;
  relationships: Array<Relationship>;
  incomes: Array<Income>;
  liabilities: Array<Liability>;
  existingCreditsFromCentralBank: Array<ExistingCreditFromCentralBank>;
  existingCreditsFromBecris: [];
  lastNbbConsulation: Date;
  lastBecrisConsulation: Date;
  employmentStatus: Resource;
  numberOfChildren: number;
  hasCompanyCar: boolean;
  role: string;
  firstTimeBuyer: boolean;
  aCard: boolean;
  aCardDetails: Resource;
  numberOfDependentPersonsWithIncome: number;
  numberOfDependentPersonsWithNoIncome: number;
  manualDsti: number;
  manualSurplus: number;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.id = data.id;
      this.civilStatus = new Resource(data.civilStatus);
      this.sex = new Resource(data.sex);
      this.emails = [];
      if (!(data.emails === undefined)) {
        data.emails.forEach(email => {
          this.emails.push(new Email(email));
        });
      }
      this.phoneNumbers = [];
      if (!(data.phoneNumbers === undefined)) {
        data.phoneNumbers.forEach(phoneNumber => {
          this.phoneNumbers.push(new PhoneNumber(phoneNumber));
        });
      }
      this.addresses = [];
      if (!(data.addresses === undefined)) {
        data.addresses.forEach(address => {
          this.addresses.push(new Address(address));
        });
      }
      this.firstName = data.firstName || '';
      this.middleName = data.middleName || '';
      this.lastName = data.lastName || '';
      this.nationality = new Resource(data.nationality);
      this.birthDate = data.birthDate || '';
      this.birthCity = data.birthCity || '';
      this.nationalNumber = data.nationalNumber || '';
      this.idIssuanceDate = data.idIssuanceDate || '';
      this.idExpirationDate = data.idExpirationDate || '';
      this.idCardNumber = data.idCardNumber || '';
      this.complianceTerror = data.complianceTerror !== undefined ? data.complianceTerror : false;
      this.complianceBank = data.complianceBank !== undefined ? data.complianceBank : false;
      this.complianceFraud = data.complianceFraud !== undefined ? data.complianceFraud : false;
      this.language = data.language || '';
      this.employmentStatus = new Resource(data.employmentStatus);
      this.relationships = [];
      if (!(data.relationships === undefined)) {
        data.relationships.forEach(relationship => {
          this.relationships.push(new Relationship(relationship));
        });
      }
      this.incomes = [];
      if (!(data.incomes === undefined)) {
        data.incomes.forEach(income => {
          this.incomes.push(new Income(income));
        });
      }
      this.liabilities = [];
      if (!(data.liabilities === undefined)) {
        data.liabilities.forEach(liability => {
          this.liabilities.push(new Liability(liability));
        });
      }
      this.existingCreditsFromCentralBank = [];
      if (!(data.existingCreditsFromCentralBank === undefined)) {
        data.existingCreditsFromCentralBank.forEach(existingCreditFromCentralBank => {
          this.existingCreditsFromCentralBank.push(new ExistingCreditFromCentralBank(existingCreditFromCentralBank));
        });
      }
      this.numberOfChildren = data.numberOfChildren || 0;
      this.hasCompanyCar = data.hasCompanyCar || false;
      this.role = data.role || '';
      this.firstTimeBuyer = data.firstTimeBuyer || false;
      this.aCard = data.aCard || false;
      this.aCardDetails = new Resource(data.aCardDetails);
      this.numberOfDependentPersonsWithIncome = data.numberOfDependentPersonsWithIncome || 0;
      this.numberOfDependentPersonsWithNoIncome = data.numberOfDependentPersonsWithNoIncome || 0;
      this.manualDsti = data.manualDsti || 0;
      this.manualSurplus = data.manualSurplus || 0;
    }
  }

  calculateEndAge(offer): number {
    const longest_duration = offer.productComboItems.reduce((maxDuration, comboItem) =>
        Math.max(maxDuration, comboItem.productItems.reduce((acc, productItem) =>
            Math.max(acc, productItem.duration),
          0)), 0);
    const birth = new Date(this.birthDate);
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + longest_duration);

    let age = endDate.getFullYear() - birth.getFullYear();

    if (endDate.getMonth() < birth.getMonth() || (endDate.getMonth() === birth.getMonth() && endDate.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  }
}

export class CentralBankConsultation {
  client: Client;
  existingCreditsFromCentralBank: Array<ExistingCreditFromCentralBank>;
  openNotification: boolean;

  constructor(data?: any) {
    if (!(data === undefined || data === null)) {
      this.client = new Client(data.client);
      this.existingCreditsFromCentralBank = new Array(data.ExistingCreditFromCentralBank);
      this.openNotification = data.openNotification === undefined ? false : data.openNotification;
    }
  }
}

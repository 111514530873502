<div class="view">
	<div class="conteinerNews">
		<div class="list-header">
			<div class="column title">
				<b>{{ 'ç.feature.thirdParty.broker.coBroker.plural' | translate }}</b>
			</div>
			<div class="action">
				<i class="uil uil-multiply" (click)="dialogRef.close()"></i>
			</div>
		</div>
	</div>
	<mat-card>
		<div class="conteinerCobrokers">
			<button class="edit" (click)="createCobroker()" [disabled]="allBrokers.length == 0">
				<i class="uil uil-plus"></i>
				<span> {{ 'ç.feature.thirdParty.broker.form.createCobroker' | translate }}</span>
			</button>
			<ng-container *ngIf="broker?.coBrokers">
				<ng-container *ngFor="let coBroker of broker.coBrokers">
					<div class="broker" *ngIf="getCobroker(coBroker)">
						<p>{{ getCobroker(coBroker).key }}</p>
						<button class="delete" (click)="deleteCobroker(coBroker)">
							<i class="uil uil-trash-alt warn"></i>
						</button>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</mat-card>
</div>

import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ResourceService} from '../resource/resource.service';
import {FormGroup, ValidatorFn} from '@angular/forms';
import {AbstractControlWithWarning} from '../../models/form-warnings/form-warning.model';
import {ValidatorService} from '../validator/validator.service';
import { Card } from '../../models/card/card.model';

@Injectable({
  providedIn: 'root',
})
export class FormWarningService {
  /**
   * FormWarningSerivce are like validations. However, they will never
   * actually provide errors.
   * The service should always set the .warnings property on a Control field.
   *
   * NOTE: Make sure to also clear your values, as this will not be done automatically
   *
   * NOTE: unless sure, always add the validators on the FormGroups, not on
   * independent item. The warnings won't propagate
   */
  constructor(
    private readonly translate: TranslateService,
    private readonly resourceService: ResourceService,
    private readonly validatorService: ValidatorService
  ) {
  }

  /**
   * Compares the startDate in the formControl to today. (now - startDate)
   * If that time frame is less than input months, it sets the
   * 'nowMinusStartDateLessThanMonths' warning field.
   *
   * Dates in the future will have negative date differences as it's today-startDate
   * @param months
   */
  public nowMinusStartDateLessThanMonths(
    months: number,
    warningKey = 'nowMinusStartDateLessThanMonths',
    startDateKey = 'startDate'
  ): ValidatorFn {
    return (control: AbstractControlWithWarning): null => {
      const startDate = new Date(control.value[startDateKey]);
      if (!startDate) {
        if (control.warnings) {
          delete control.warnings[warningKey];
        }
        return null;
      }
      const now = new Date();

      const difference = this.validatorService.calculateDiff(startDate, now, 'month');

      if (difference < months) {
        if (!control.warnings) {
          control.warnings = {};
        }
        control.warnings[warningKey] = {minMonths: months};
      } else {
        if (control.warnings) {
          delete control.warnings[warningKey];
        }
      }
      return null;
    };
  }

  public countryNotBelgium(): ValidatorFn {
    return (control: AbstractControlWithWarning): null => {
      const country = control.value['country.id'];
      if (!country) {
        if (control.warnings) {
          delete control.warnings.countryNotBelgium;
        }
        return null;
      }

      const belgium = this.resourceService.getResourceInstance('country', 'definition', 'BE').id;

      if (country !== belgium) {
        if (!control.warnings) {
          control.warnings = {};
        }
        control.warnings.countryNotBelgium = {};
      } else {
        if (control.warnings) {
          delete control.warnings.countryNotBelgium;
        }
      }
      return null;
    };
  }

  public countryNotBeNeLux(): ValidatorFn {
    return (control: AbstractControlWithWarning): null => {
      const country = control.value['country.id'];
      if (!country) {
        if (control.warnings) {
          delete control.warnings.countryNotBeNeLux;
        }
        return null;
      }

      const beNeLux = [
        this.resourceService.getResourceInstance('country', 'definition', 'BE').id,
        this.resourceService.getResourceInstance('country', 'definition', 'NL').id,
        this.resourceService.getResourceInstance('country', 'definition', 'LU').id,
      ];

      const beNeLuxAndOther = [
        ...beNeLux,
        this.resourceService.getResourceInstance('country', 'definition', 'FR').id,
        this.resourceService.getResourceInstance('country', 'definition', 'DE').id,
      ];

      if (!beNeLuxAndOther.includes(country)) {
        if (!control.warnings) {
          control.warnings = {};
        }
        control.warnings.countryNotBeNeLux = {};
      } else {
        if (control.warnings) {
          delete control.warnings.countryNotBeNeLux;
        }
      }
      return null;
    };
  }

  checkIfClientWhoHaveChildrenHaveChildrenIncome(cardIncomes: Array<Card>, role) {
    return (control: AbstractControlWithWarning) => {
      if (role.definition !== 'borrower') {
        return null
      }
      const numberOfChildren = control.value['numberOfChildren'];
      if (numberOfChildren > 0){
        if (cardIncomes.filter(card => card.meta.incomeType.definition === "childBenefit" && !card.toDelete).length === 0){
          if (control.warnings){
            control.warnings.checkIfClientWhoHaveChildrenHaveChildrenIncome = {};
          }
        } else {
          if (control.warnings) {
            delete control.warnings.checkIfClientWhoHaveChildrenHaveChildrenIncome;
          }
        }
      }
      return null;
    };
  }

  public formatWarning(key, options = null) {
    switch (key) {
      case 'nowMinusStartDateLessThanMonths':
        return this.translate.instant(`ç.question.warning.nowMinusStartDateLessThanMonths`, options);
      case 'liabilityNowMinusStartDateLessThanMonths':
        return this.translate.instant(`ç.question.warning.liabilityNowMinusStartDateLessThanMonths`, options);
      case 'costsChangedMoreThan1000Euros':
        return `ç.question.warning.costsChangedMoreThan1000Euros`;
      case 'countryNotBelgium':
        return `ç.question.warning.countryNotBelgium`;
      case 'countryNotBeNeLux':
        return `ç.question.warning.countryNotBeNeLux`;
      case `maxHousingUnits`:
        return `ç.question.warning.maxHousingUnits`;
      case`checkIfClientWhoHaveChildrenHaveChildrenIncome`:
          return `ç.feature.validator.checkIfClientWhoHaveChildrenHaveChildrenIncome`;
      default:
        return `ç.question.warning.default`;
    }
  }

  public formatWarnings(warnings) {
    return Object.keys(warnings).map((key) => {
      return this.translate.instant(this.formatWarning(key, warnings[key]));
    });
  }
}

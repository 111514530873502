import {Pipe, PipeTransform} from '@angular/core';
import {AppDate} from '../../models/app-date/app-date';
import {DatePipe} from '@angular/common';
import {LocaleService} from '../../../core/services/locale.service';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  constructor(private localeService: LocaleService) {
    super(localeService.locale);
  }

  transform(value: any, args: any = AppDate.getDateFormat(value, this.localeService.region)): any {
    if (value && value.length === 4) {
      return value;
    }
    return super.transform(value, args);
  }
}
